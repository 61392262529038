import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  UUID: any;
};

/** Accept the user agreement for the current user */
export type AcceptUserAgreement = {
  __typename: 'AcceptUserAgreement';
  success: Scalars['Boolean'];
};

/** Action object type */
export type Action = {
  __typename: 'Action';
  link: Scalars['String'];
  text: Scalars['String'];
  type: ActionType;
};

/** An enumeration. */
export enum ActionType {
  /** External Link */
  ExternalLink = 'EXTERNAL_LINK',
  /** Internal Link */
  InternalLink = 'INTERNAL_LINK'
}

/**
 * Api Token object type
 *
 * API tokens are used to authenticate with the Model Data System. The generated token
 * value should be included in the Authorization header using the Bearer scheme,
 * `Authorization: Bearer <token>`. The token value is only shown once after an API
 * token is created.
 */
export type ApiToken = {
  __typename: 'ApiToken';
  created: Scalars['DateTime'];
  /** An optional description for the API token */
  description?: Maybe<Scalars['String']>;
  /** A unique identifier for the API token */
  id: Scalars['ID'];
  user: User;
};

/**
 * The state/result of an authenticate mutation
 *
 * Not every error state needs to be included in this enum, only ones for which it'd be
 * useful to programmatically respond to. E.g. `AuthState.MFA_CODE_REQUIRED` is
 * included as login forms need to know when to ask for an MFA code, but when a
 * description is missing for an API token a regular GraphQL error is returned as
 * that's simply a bug in the client application.
 */
export enum AuthState {
  /** Email and/or password were incorrect */
  BadCredentials = 'BAD_CREDENTIALS',
  /** Credentials were correct, MFA code was not */
  BadMfaCode = 'BAD_MFA_CODE',
  /** Credentials were correct, but an MFA code is required. Re-submit the mutation with the same email and password, plus an MFA code */
  MfaCodeRequired = 'MFA_CODE_REQUIRED',
  /** Authentication was successful. A token should be provided */
  Success = 'SUCCESS',
  /** Too many authentication attempts have been made recently; try again later */
  Throttled = 'THROTTLED',
  /** The user account is not set up. They must complete set up on the Web Portal before creating a `TokenType.API` token. This value is also returned if MFA is enforced but not set up for the user */
  UserNotSetUp = 'USER_NOT_SET_UP'
}

/**
 * Retrieve an auth token. Assuming credentials are correct, the basic flow is:
 *
 * 1. Submit the mutation with email/password and also the type of token you want. At
 *    this point you don't know if an MFA code is required
 * 2. If the mutation is successful, the token should be included in the response. This
 *    is the case where the user does not have MFA enabled/required
 * 3. If the mutation state is `AuthState.MFA_CODE_REQUIRED`, re-submit the mutation
 *    with the same email/password _and_ a fresh MFA code from the user
 * 4. The mutation should now be successful (assuming the MFA code is valid)
 *
 * If the requested token type is `TokenType.API`, a value for `api_token_description`
 * is required. If an API token with a matching description exists, it will be
 * returned, otherwise a new one will be created. This allows the same API token to be
 * returned multiple times (which prevents us from creating too many API tokens).
 *
 * `TokenType.USER` tokens have an expiry; these are what the Web Portal uses.
 *
 * If the user does not exist in our Auth system, we will try to authenticate with Alphasense
 * for API token users. In this case, the basic flow is:
 *
 * 1. Submit the request to Alphasense with the provided username and password
 * 2. If the authentication is successful, mint a JWT with the shared Alphasense user and
 *     the provided email
 * 3. return said JWT
 */
export type Authenticate = {
  __typename: 'Authenticate';
  /** The number of seconds to wait before retrying. Only available with state `AuthState.THROTTLED` */
  retryAfter?: Maybe<Scalars['Int']>;
  /** The response state. See the `AuthState` enum for details */
  state: AuthState;
  /** More information about the current `state` (when available) */
  stateExplanation?: Maybe<Scalars['String']>;
  /** The token (either an API token or user token). Only provided for mutations with state `AuthState.SUCCESS` */
  token?: Maybe<Scalars['String']>;
};

/**
 * Category object type
 *
 * Subset of Folder. Used on the companies page instead of Folder to
 * increase performance.
 */
export type Category = {
  __typename: 'Category';
  /** Category FIID */
  id: Scalars['ID'];
  /** List of file FIIDs that have file links in this category */
  linkedFileIds: Array<Scalars['ID']>;
  /** Category name */
  name: Scalars['String'];
  /** Parent category FIID */
  parentId: Scalars['ID'];
};

export type CompaniesFilterInputType = {
  column: Scalars['String'];
  operator: Scalars['String'];
  order: Scalars['Int'];
  value: Scalars['String'];
};

/** CompaniesFilterPreset object type */
export type CompaniesFilterPreset = {
  __typename: 'CompaniesFilterPreset';
  column: Scalars['String'];
  operator: Scalars['String'];
  order: Scalars['Int'];
  value: Scalars['String'];
};

/**
 * Companies list object type
 *
 * Contains list of companies and timestamp of when the companies data was generated
 */
export type CompaniesList = {
  __typename: 'CompaniesList';
  /** List of companies to display */
  companies: Array<Company>;
  /** Companies list data update timestamp */
  updated: Scalars['DateTime'];
};

export type CompaniesPresetInputType = {
  categoryIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  filtering?: Maybe<Array<Maybe<CompaniesFilterInputType>>>;
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  sorting?: Maybe<Array<Maybe<CompaniesSortInputType>>>;
};

export type CompaniesSortInputType = {
  column: Scalars['String'];
  direction: SortDirectionType;
};

/** CompaniesSortPreset object type */
export type CompaniesSortPreset = {
  __typename: 'CompaniesSortPreset';
  column: Scalars['String'];
  direction: CompaniesSortPresetDirection;
};

/** An enumeration. */
export enum CompaniesSortPresetDirection {
  /** Ascending */
  Asc = 'ASC',
  /** Descending */
  Desc = 'DESC'
}

/** CompaniesTablePreset object type */
export type CompaniesTablePreset = {
  __typename: 'CompaniesTablePreset';
  categoryIds?: Maybe<Array<Scalars['String']>>;
  /** A list of filtering presets */
  filtering: Array<CompaniesFilterPreset>;
  id: Scalars['ID'];
  name: Scalars['String'];
  /** Whether the preset should be viewable by all users */
  private: Scalars['Boolean'];
  /** A list of sorting presets */
  sorting: Array<CompaniesSortPreset>;
  user?: Maybe<User>;
};

/**
 * Company object type
 *
 * Subset of File, with specific fields aggregated from FileMetadata and FileFavourite.
 * Used on the companies page instead of Resource to increase performance.
 */
export type Company = {
  __typename: 'Company';
  /** FIID of category the company is in */
  folderId: Scalars['ID'];
  /** Company FIID */
  id: Scalars['ID'];
  /** Company name */
  name: Scalars['String'];
  /** Company most recent quarter */
  period?: Maybe<Scalars['String']>;
  /** Company MRFY revenue */
  revenue?: Maybe<Scalars['Float']>;
  /** Company ticker */
  ticker?: Maybe<Scalars['String']>;
  /** Company last event update type */
  updateType?: Maybe<Scalars['String']>;
  /** Company last event update date */
  updated?: Maybe<Scalars['String']>;
  /** Whether company is watch listed */
  watchListed: Scalars['Boolean'];
};

/** Create an API token for a user */
export type CreateApiToken = {
  __typename: 'CreateApiToken';
  id: Scalars['ID'];
  token: Scalars['String'];
};

export type CreateCompaniesTablePreset = {
  __typename: 'CreateCompaniesTablePreset';
  preset: CompaniesTablePreset;
};

/** Create a folder */
export type CreateFolder = {
  __typename: 'CreateFolder';
  folderName: Scalars['String'];
};

/** Reset a user's Auth0 MFA */
export type CreateMfaEnrollmentUrl = {
  __typename: 'CreateMfaEnrollmentUrl';
  url: Scalars['String'];
};

/** Generate a password reset ticket for a user */
export type CreatePasswordResetTicket = {
  __typename: 'CreatePasswordResetTicket';
  ticket: Scalars['String'];
};

/** Create & send a placeholder request */
export type CreatePlaceholderRequest = {
  __typename: 'CreatePlaceholderRequest';
  success: Scalars['Boolean'];
};

/**
 * Create a shortened URL. Usage is tracked through Segment
 *
 * This feature is only available to staff members.
 */
export type CreateShortUrl = {
  __typename: 'CreateShortUrl';
  shortUrl: ShortUrl;
};

/** Create & send the support request */
export type CreateSupportRequest = {
  __typename: 'CreateSupportRequest';
  success: Scalars['Boolean'];
};


/** Delete an API Token for a user */
export type DeleteApiToken = {
  __typename: 'DeleteApiToken';
  id: Scalars['ID'];
};

export type DeleteCompaniesTablePreset = {
  __typename: 'DeleteCompaniesTablePreset';
  success?: Maybe<Scalars['Boolean']>;
};

/** Delete a resource file */
export type DeleteResourceFile = {
  __typename: 'DeleteResourceFile';
  id: Scalars['ID'];
};

/** Disables a user's MFA */
export type DisableMfa = {
  __typename: 'DisableMfa';
  success: Scalars['Boolean'];
};

/** GraphQL enum type for DriversWorksheetsVariantType */
export enum DriversWorksheetsKind {
  Null = 'NULL',
  StandardFcf = 'STANDARD_FCF'
}

/** Contains a user's email settings and IPO subscription preferences */
export type EmailSettings = {
  __typename: 'EmailSettings';
  emailRecentDownloadUpdate: Scalars['Boolean'];
  ipoSubscriptions: IpoSubscriptions;
  user?: Maybe<User>;
};

/**
 * Mutation to enable MFA for the current user
 *
 * Users will get the information to set up MFA on a device from the myMfaSetupInfo
 * query. After MFA is setup on a device, the device will provide them with a token
 * which can be used in this mutation to enable MFA for their account
 *
 * The requesting user is returned from the mutation so that their mfaSetupComplete
 * value is updated in the Apollo cache
 */
export type EnableMeMfa = {
  __typename: 'EnableMeMfa';
  user: User;
};

/** Represents file types of Resource files (ResourceKind.other) */
export enum FileTypeKind {
  Dashboard = 'DASHBOARD',
  DropinTemplate = 'DROPIN_TEMPLATE',
  Executable = 'EXECUTABLE',
  GuidePdf = 'GUIDE_PDF',
  Other = 'OTHER'
}

/** Folder object type. A core component of the WP. Resources, placeholders, folders */
export type Folder = {
  __typename: 'Folder';
  ancestorFolders: Array<Folder>;
  created: Scalars['DateTime'];
  folderTags: Array<Maybe<Scalars['String']>>;
  icon: IconKind;
  id: Scalars['ID'];
  isDeleted: Scalars['Boolean'];
  itemCountRecursive: Scalars['Int'];
  items: FolderItemConnection;
  mePermissions: Array<Maybe<Scalars['String']>>;
  name: Scalars['String'];
  parentFolder?: Maybe<Folder>;
  path: Scalars['String'];
  placeholderCount: Scalars['Int'];
  resourceCount: Scalars['Int'];
  slug: Scalars['String'];
  updated: Scalars['DateTime'];
};


/** Folder object type. A core component of the WP. Resources, placeholders, folders */
export type FolderItemsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  ordering?: Maybe<FolderItemOrdering>;
  reverseOrdering?: Maybe<Scalars['Boolean']>;
};

/** GraphQL connection for the FolderType */
export type FolderConnection = {
  __typename: 'FolderConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FolderEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `Folder` and its cursor. */
export type FolderEdge = {
  __typename: 'FolderEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<Folder>;
};

/** A union of object types that can be contained in a folder */
export type FolderItem = Folder | Placeholder | Resource;

/** A connection (augmented list for pagination) of folder items */
export type FolderItemConnection = {
  __typename: 'FolderItemConnection';
  edges: Array<FolderItemEdge>;
  pageInfo: PageInfo;
};

/** A folder item edge (part of a connection type) */
export type FolderItemEdge = {
  __typename: 'FolderItemEdge';
  cursor: Scalars['String'];
  node: FolderItem;
};

/** Represents ordering options for FolderItem objects */
export enum FolderItemOrdering {
  Name = 'NAME',
  Updated = 'UPDATED'
}

/** Group object type */
export type Group = {
  __typename: 'Group';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

/** Represents icons for Resource objects */
export enum IconKind {
  Document = 'DOCUMENT',
  Executable = 'EXECUTABLE',
  Folder = 'FOLDER',
  Other = 'OTHER',
  Spreadsheet = 'SPREADSHEET'
}

/** A Folder in terms of a user's IPO subscriptions */
export type IpoSubscriptionFolder = {
  __typename: 'IpoSubscriptionFolder';
  id: Scalars['ID'];
  isChecked: Scalars['Boolean'];
  name: Scalars['String'];
  sector: Scalars['String'];
};

/** Input type used to define the structure of the IpoSubscriptions folder input data */
export type IpoSubscriptionFolderInput = {
  id: Scalars['ID'];
  isChecked: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  sector?: Maybe<Scalars['String']>;
};

/** Input type used to define the structure of the IpoSubscriptions input data */
export type IpoSubscriptionInput = {
  allFolders: Scalars['Boolean'];
  folders: Array<Maybe<IpoSubscriptionFolderInput>>;
};

/** IpoSubscriptions object type to represent a user's IPO subscription preferences */
export type IpoSubscriptions = {
  __typename: 'IpoSubscriptions';
  /** True if a user has subscribed to all available folders; otherwise false. */
  allFolders: Scalars['Boolean'];
  folders: Array<IpoSubscriptionFolder>;
};

/**
 * NOTE: This type is deprecated. See model_api.model_series_type.ModelSeriesType
 * for future use.
 *
 * Represents a series of a models for a given CSIN
 *
 * A model series has a number of versions each represented by a ResourceType.
 * This allows us to access files directly by CSIN without worrying about
 * folder structure
 */
export type ModelSeries = {
  __typename: 'ModelSeries';
  id: Scalars['ID'];
  latestModel?: Maybe<Resource>;
  modelByVersion?: Maybe<Resource>;
  models: Array<Resource>;
};


/**
 * NOTE: This type is deprecated. See model_api.model_series_type.ModelSeriesType
 * for future use.
 *
 * Represents a series of a models for a given CSIN
 *
 * A model series has a number of versions each represented by a ResourceType.
 * This allows us to access files directly by CSIN without worrying about
 * folder structure
 */
export type ModelSeriesModelByVersionArgs = {
  version: Scalars['String'];
};

/** GraphQL enum type for ModelUpdateType */
export enum ModelUpdateType {
  AnnualGrouped = 'ANNUAL_GROUPED',
  CompanyAnnouncement = 'COMPANY_ANNOUNCEMENT',
  CompanyEarnings = 'COMPANY_EARNINGS',
  DcfTab = 'DCF_TAB',
  LboTab = 'LBO_TAB',
  ModelImprovement = 'MODEL_IMPROVEMENT',
  Other = 'OTHER'
}

/** Mutation type */
export type Mutation = {
  __typename: 'Mutation';
  /** Accept the user agreement for the current user */
  acceptUserAgreement?: Maybe<AcceptUserAgreement>;
  /**
   * Retrieve an auth token. Assuming credentials are correct, the basic flow is:
   *
   * 1. Submit the mutation with email/password and also the type of token you want. At
   *    this point you don't know if an MFA code is required
   * 2. If the mutation is successful, the token should be included in the response. This
   *    is the case where the user does not have MFA enabled/required
   * 3. If the mutation state is `AuthState.MFA_CODE_REQUIRED`, re-submit the mutation
   *    with the same email/password _and_ a fresh MFA code from the user
   * 4. The mutation should now be successful (assuming the MFA code is valid)
   *
   * If the requested token type is `TokenType.API`, a value for `api_token_description`
   * is required. If an API token with a matching description exists, it will be
   * returned, otherwise a new one will be created. This allows the same API token to be
   * returned multiple times (which prevents us from creating too many API tokens).
   *
   * `TokenType.USER` tokens have an expiry; these are what the Web Portal uses.
   *
   * If the user does not exist in our Auth system, we will try to authenticate with Alphasense
   * for API token users. In this case, the basic flow is:
   *
   * 1. Submit the request to Alphasense with the provided username and password
   * 2. If the authentication is successful, mint a JWT with the shared Alphasense user and
   *     the provided email
   * 3. return said JWT
   */
  authenticate?: Maybe<Authenticate>;
  /** Create an API token for a user */
  createApiToken?: Maybe<CreateApiToken>;
  /** Generate a password reset ticket for a user */
  createAuth0PasswordResetToken?: Maybe<CreatePasswordResetTicket>;
  createCompaniesTablePreset?: Maybe<CreateCompaniesTablePreset>;
  /** Create a folder */
  createFolder?: Maybe<CreateFolder>;
  /** Create & send a placeholder request */
  createPlaceholderRequest?: Maybe<CreatePlaceholderRequest>;
  /**
   * Create a shortened URL. Usage is tracked through Segment
   *
   * This feature is only available to staff members.
   */
  createShortUrl?: Maybe<CreateShortUrl>;
  /** Create & send the support request */
  createSupportRequest?: Maybe<CreateSupportRequest>;
  /** Delete an API Token for a user */
  deleteApiToken?: Maybe<DeleteApiToken>;
  deleteCompaniesTablePreset?: Maybe<DeleteCompaniesTablePreset>;
  /** Delete a resource file */
  deleteResourceFile?: Maybe<DeleteResourceFile>;
  /** Disables a user's MFA */
  disableMfa?: Maybe<DisableMfa>;
  /**
   * Mutation to enable MFA for the current user
   *
   * Users will get the information to set up MFA on a device from the myMfaSetupInfo
   * query. After MFA is setup on a device, the device will provide them with a token
   * which can be used in this mutation to enable MFA for their account
   *
   * The requesting user is returned from the mutation so that their mfaSetupComplete
   * value is updated in the Apollo cache
   */
  enableMfa?: Maybe<EnableMeMfa>;
  /** Regenerate a user's Auth0 MFA recovery token */
  regenerateMfaRecoveryTokenAuth0?: Maybe<RegenerateMfaRecoveryToken>;
  /** Reset a user's Auth0 MFA */
  resetMfaAuth0?: Maybe<ResetMfa>;
  /** Reset a user's Auth0 MFA */
  setUpMfaAuth0?: Maybe<CreateMfaEnrollmentUrl>;
  /** Toggles a WatchListItem */
  toggleWatchListItem?: Maybe<ToggleWatchListItem>;
  /** Update a user's email settings and IPO subscription preferences */
  updateEmailSettings?: Maybe<UpdateEmailSettings>;
  /** Mutation to update the MFA backup tokens for Me */
  updateMfaBackupTokens?: Maybe<UpdateMeMfaBackupTokens>;
  /**
   * Update the current user's password
   *
   * All Django sessions for the user will be invalidated when the user's password is
   * successfully changed. While JWTs will remain valid, if the application also relies
   * on Django session authentication it'd be a good idea to ask the user to log in
   * again right away. Otherwise the user will experience a surprise login form later
   * when session auth is required (e.g. if they try to access a legacy WPv1 page).
   */
  updatePassword?: Maybe<UpdateMePassword>;
  /**
   * Mutation to update user's a preferred default variant dimensions
   *
   * Note: If user ID omitted defaults to updating user making the request
   */
  updateVariantPreference?: Maybe<UpdateVariantPreference>;
  /** Updates the watch list for a File or FileLink */
  updateWatchList?: Maybe<UpdateWatchList>;
};


/** Mutation type */
export type MutationAuthenticateArgs = {
  apiTokenDescription?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  mfaCode?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  tokenType: TokenType;
};


/** Mutation type */
export type MutationCreateApiTokenArgs = {
  amsUserId?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
};


/** Mutation type */
export type MutationCreateAuth0PasswordResetTokenArgs = {
  userId?: Maybe<Scalars['ID']>;
};


/** Mutation type */
export type MutationCreateCompaniesTablePresetArgs = {
  presetInput: CompaniesPresetInputType;
};


/** Mutation type */
export type MutationCreateFolderArgs = {
  folderName: Scalars['String'];
  parentFolderFiid: Scalars['String'];
};


/** Mutation type */
export type MutationCreatePlaceholderRequestArgs = {
  placeholderId: Scalars['ID'];
};


/** Mutation type */
export type MutationCreateShortUrlArgs = {
  longUrl: Scalars['String'];
};


/** Mutation type */
export type MutationCreateSupportRequestArgs = {
  companyOrTicker?: Maybe<Scalars['String']>;
  modelUpdateType?: Maybe<ModelUpdateType>;
  requestBody?: Maybe<Scalars['String']>;
  requestType: RequestKind;
};


/** Mutation type */
export type MutationDeleteApiTokenArgs = {
  id: Scalars['ID'];
};


/** Mutation type */
export type MutationDeleteCompaniesTablePresetArgs = {
  id: Scalars['ID'];
};


/** Mutation type */
export type MutationDeleteResourceFileArgs = {
  id: Scalars['ID'];
};


/** Mutation type */
export type MutationDisableMfaArgs = {
  userId?: Maybe<Scalars['ID']>;
};


/** Mutation type */
export type MutationEnableMfaArgs = {
  token: Scalars['String'];
};


/** Mutation type */
export type MutationRegenerateMfaRecoveryTokenAuth0Args = {
  userId: Scalars['ID'];
};


/** Mutation type */
export type MutationResetMfaAuth0Args = {
  generateTicket?: Maybe<Scalars['Boolean']>;
  userId: Scalars['ID'];
};


/** Mutation type */
export type MutationSetUpMfaAuth0Args = {
  userId: Scalars['ID'];
};


/** Mutation type */
export type MutationToggleWatchListItemArgs = {
  resourceId: Scalars['ID'];
};


/** Mutation type */
export type MutationUpdateEmailSettingsArgs = {
  emailRecentDownloadUpdate?: Maybe<Scalars['Boolean']>;
  ipoSubscriptions?: Maybe<IpoSubscriptionInput>;
  userId?: Maybe<Scalars['ID']>;
};


/** Mutation type */
export type MutationUpdatePasswordArgs = {
  newPassword: Scalars['String'];
  newPasswordConfirmed: Scalars['String'];
  oldPassword: Scalars['String'];
};


/** Mutation type */
export type MutationUpdateVariantPreferenceArgs = {
  driversWorksheets: DriversWorksheetsKind;
  periodOrder: PeriodOrderKind;
  theme?: Maybe<ThemeKind>;
  userId?: Maybe<Scalars['ID']>;
};


/** Mutation type */
export type MutationUpdateWatchListArgs = {
  resourceId: Scalars['ID'];
  userIds?: Maybe<Array<Scalars['ID']>>;
};

/** An object with an ID */
export type Node = {
  /** The ID of the object. */
  id: Scalars['ID'];
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

/** GraphQL enum type for PeriodOrderVariantType */
export enum PeriodOrderKind {
  AnnualGrouped = 'ANNUAL_GROUPED',
  Chronological = 'CHRONOLOGICAL'
}

/** Placeholder object type (typically a resource that is not available yet) */
export type Placeholder = {
  __typename: 'Placeholder';
  created: Scalars['DateTime'];
  folder: Folder;
  icon: IconKind;
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  updated: Scalars['DateTime'];
};

/** Promo object type */
export type Promo = {
  __typename: 'Promo';
  action: Action;
  description: Scalars['String'];
  imageAltText: Scalars['String'];
  imageUrl: Scalars['String'];
  title: Scalars['String'];
};

/** Base Query object type */
export type Query = {
  __typename: 'Query';
  /** Get the companies page category list */
  categoryList: Array<Category>;
  /** Get the companies page company list */
  companiesList: CompaniesList;
  /** Get a list of presets for the Companies Table */
  companiesTablePresets: Array<CompaniesTablePreset>;
  /** Get a file by ID */
  file?: Maybe<Resource>;
  /** Get the most recent version of a file by filename */
  fileByFilename?: Maybe<Resource>;
  /** Retrieve all files in a folder recursively (i.e. explore the folder subtree) */
  filesByFolderRecursive?: Maybe<ResourceConnection>;
  folder?: Maybe<Folder>;
  folderByPath?: Maybe<Folder>;
  folderItem?: Maybe<FolderItem>;
  /** Returns the folder hierarchy of folders on the Web Portal */
  folderTree?: Maybe<FolderConnection>;
  /** Returns groups matching the search query */
  groups?: Maybe<Array<Group>>;
  me: User;
  /** @deprecated Use the modelSeries query at the /model-workbooks endpoint */
  modelSeries?: Maybe<ModelSeries>;
  myApiTokens: Array<ApiToken>;
  myMfaBackupTokens: Array<Scalars['String']>;
  /** Returns a URL with information for a user to set up MFA on their account */
  myMfaSetupUrl: Scalars['String'];
  /** Return shortened URLs created by the current user in reverse-chronological order */
  myShortUrls: Array<ShortUrl>;
  /** Returns the visible promo items ordered by their sorting index */
  promos: Array<Promo>;
  /** Gets a File or FileLink by its folder path and slug */
  resourceByPathAndSlug?: Maybe<Resource>;
  /** Returns resource display items */
  resourceDisplays: Array<ResourceDisplay>;
  /** Get a resource file by ID */
  resourceFile?: Maybe<Resource>;
  /** Returns a list of resource files and worksheet metadata a user has access to */
  resourceFiles?: Maybe<Array<Resource>>;
  search: SearchItemConnection;
  /** Returns the user's setup state: if setup is complete, any remaining tasks, etc. */
  userSetup: UserSetup;
  /** Returns a list of users matching the search query */
  users: Array<User>;
  /** Returns a list of users watching the specified file */
  usersWatching: Array<User>;
  /** Returns a watch list by AMS user id */
  watchListForAmsUser?: Maybe<Array<Resource>>;
};


/** Base Query object type */
export type QueryCompaniesListArgs = {
  lastUpdate?: Maybe<Scalars['DateTime']>;
};


/** Base Query object type */
export type QueryFileArgs = {
  id?: Maybe<Scalars['ID']>;
};


/** Base Query object type */
export type QueryFileByFilenameArgs = {
  filename: Scalars['String'];
};


/** Base Query object type */
export type QueryFilesByFolderRecursiveArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  csin?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  folder?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  latestVersion?: Maybe<Scalars['Boolean']>;
  nameContains?: Maybe<Scalars['String']>;
  tickerContains?: Maybe<Scalars['String']>;
};


/** Base Query object type */
export type QueryFolderArgs = {
  id: Scalars['ID'];
};


/** Base Query object type */
export type QueryFolderByPathArgs = {
  path: Scalars['String'];
};


/** Base Query object type */
export type QueryFolderItemArgs = {
  id: Scalars['ID'];
  latestVersion?: Maybe<Scalars['Boolean']>;
};


/** Base Query object type */
export type QueryFolderTreeArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Base Query object type */
export type QueryGroupsArgs = {
  first?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
};


/** Base Query object type */
export type QueryModelSeriesArgs = {
  id: Scalars['ID'];
};


/** Base Query object type */
export type QueryMyShortUrlsArgs = {
  count: Scalars['Int'];
};


/** Base Query object type */
export type QueryPromosArgs = {
  count: Scalars['Int'];
};


/** Base Query object type */
export type QueryResourceByPathAndSlugArgs = {
  fileSlug: Scalars['String'];
  folderPath: Scalars['String'];
};


/** Base Query object type */
export type QueryResourceDisplaysArgs = {
  count: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
};


/** Base Query object type */
export type QueryResourceFileArgs = {
  id?: Maybe<Scalars['ID']>;
};


/** Base Query object type */
export type QueryResourceFilesArgs = {
  fileType?: Maybe<Scalars['String']>;
  latestVersion?: Maybe<Scalars['Boolean']>;
};


/** Base Query object type */
export type QuerySearchArgs = {
  after?: Maybe<Scalars['String']>;
  first: Scalars['Int'];
  folder?: Maybe<Scalars['ID']>;
  query: Scalars['String'];
};


/** Base Query object type */
export type QueryUsersArgs = {
  canViewId?: Maybe<Scalars['ID']>;
  excludeMe?: Maybe<Scalars['Boolean']>;
  first?: Maybe<Scalars['Int']>;
  query: Scalars['String'];
};


/** Base Query object type */
export type QueryUsersWatchingArgs = {
  excludeMe?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
};


/** Base Query object type */
export type QueryWatchListForAmsUserArgs = {
  amsUserId: Scalars['Int'];
};

/** Regenerate a user's Auth0 MFA recovery token */
export type RegenerateMfaRecoveryToken = {
  __typename: 'RegenerateMfaRecoveryToken';
  token: Scalars['String'];
};

/** GraphQL enum type for RequestType */
export enum RequestKind {
  AddSeats = 'ADD_SEATS',
  ModelRequest = 'MODEL_REQUEST',
  ModelUpdate = 'MODEL_UPDATE',
  Other = 'OTHER'
}

/** Reset a user's Auth0 MFA */
export type ResetMfa = {
  __typename: 'ResetMfa';
  success: Scalars['Boolean'];
  url?: Maybe<Scalars['String']>;
};

/**
 * Resource object type. A downloadable resource (File, FileLink, ResourceFile)
 *
 * Note that this class does not extend from DjangoObjectType as it represents 3
 * distinct models: File, FileLink, and ResourceFile. DjangoObjectType does not have
 * support for multiple models so we handle them manually here.
 */
export type Resource = Node & {
  __typename: 'Resource';
  allVersions: ResourceVersionConnection;
  created: Scalars['DateTime'];
  csin?: Maybe<Scalars['String']>;
  downloadPresignedUrl: Scalars['String'];
  downloadUrl: Scalars['String'];
  fileType?: Maybe<FileTypeKind>;
  folder: Folder;
  icon: IconKind;
  id: Scalars['ID'];
  isCustom?: Maybe<Scalars['Boolean']>;
  kind: ResourceKind;
  modelSeries?: Maybe<ModelSeries>;
  mostRecentQuarter?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  seriesId?: Maybe<Scalars['ID']>;
  slug: Scalars['String'];
  tegusLink?: Maybe<Scalars['String']>;
  updateEventDate?: Maybe<Scalars['DateTime']>;
  updateType?: Maybe<UpdateTypeKind>;
  updateTypeDisplay?: Maybe<Scalars['String']>;
  updated: Scalars['DateTime'];
  updatedBy: User;
  variantByDimensions?: Maybe<ResourceVariant>;
  variants: Array<ResourceVariant>;
  variantsExpected: Scalars['Boolean'];
  version?: Maybe<Scalars['String']>;
  watchListed?: Maybe<Scalars['Boolean']>;
  worksheetMetadata?: Maybe<Array<Maybe<ResourceFileWorksheetMetadata>>>;
};


/**
 * Resource object type. A downloadable resource (File, FileLink, ResourceFile)
 *
 * Note that this class does not extend from DjangoObjectType as it represents 3
 * distinct models: File, FileLink, and ResourceFile. DjangoObjectType does not have
 * support for multiple models so we handle them manually here.
 */
export type ResourceAllVersionsArgs = {
  after?: Maybe<Scalars['String']>;
  first: Scalars['Int'];
};


/**
 * Resource object type. A downloadable resource (File, FileLink, ResourceFile)
 *
 * Note that this class does not extend from DjangoObjectType as it represents 3
 * distinct models: File, FileLink, and ResourceFile. DjangoObjectType does not have
 * support for multiple models so we handle them manually here.
 */
export type ResourceVariantByDimensionsArgs = {
  driversWorksheets?: Maybe<Scalars['String']>;
  periodOrder: Scalars['String'];
  theme?: Maybe<Scalars['String']>;
};

/** GraphQL connection for the ResourceType */
export type ResourceConnection = {
  __typename: 'ResourceConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ResourceEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

export type ResourceDisplay = {
  __typename: 'ResourceDisplay';
  description: Scalars['String'];
  downloadUrl?: Maybe<Scalars['String']>;
  fiid?: Maybe<Scalars['String']>;
  imageAltText: Scalars['String'];
  imageUrl: Scalars['String'];
  /** The type of the resource file associated with the ResourceDisplay */
  resourceType?: Maybe<ResourceDisplayResourceType>;
  seriesId: Scalars['UUID'];
  slug?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

/** An enumeration. */
export enum ResourceDisplayResourceType {
  /** Drop-in Template */
  DropinTemplate = 'DROPIN_TEMPLATE',
  /** Other */
  Other = 'OTHER',
  /** Toolkit */
  Toolkit = 'TOOLKIT'
}

/** A Relay edge containing a `Resource` and its cursor. */
export type ResourceEdge = {
  __typename: 'ResourceEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<Resource>;
};

/** Worksheet metadata for a resource file */
export type ResourceFileWorksheetMetadata = {
  __typename: 'ResourceFileWorksheetMetadata';
  periodOrder: Scalars['String'];
  reportingFrequency: Scalars['String'];
  sheetId: Scalars['Int'];
  sheetName: Scalars['String'];
};

/** Represents kinds of Resource objects */
export enum ResourceKind {
  Model = 'MODEL',
  Other = 'OTHER'
}

/** Resource variant object type. Represents a variation of a ResourceType */
export type ResourceVariant = {
  __typename: 'ResourceVariant';
  description: Scalars['String'];
  downloadUrl: Scalars['String'];
  driversWorksheets: DriversWorksheetsKind;
  id: Scalars['ID'];
  name: Scalars['String'];
  periodOrder: PeriodOrderKind;
  theme: ThemeKind;
  /** @deprecated Field name changed to period_order */
  type?: Maybe<Scalars['String']>;
  userPreferred: Scalars['Boolean'];
};

/** A connection (augmented list for pagination) of resource versions */
export type ResourceVersionConnection = {
  __typename: 'ResourceVersionConnection';
  edges: Array<ResourceVersionEdge>;
  pageInfo: PageInfo;
};

/** A resource version item edge (part of a connection type) */
export type ResourceVersionEdge = {
  __typename: 'ResourceVersionEdge';
  cursor: Scalars['String'];
  node: Resource;
};

/** A connection (augmented list for pagination) of search items */
export type SearchItemConnection = {
  __typename: 'SearchItemConnection';
  edges: Array<SearchItemEdge>;
  pageInfo: PageInfo;
};

/** A search item edge (part of a connection type) */
export type SearchItemEdge = {
  __typename: 'SearchItemEdge';
  cursor: Scalars['String'];
  node: FolderItem;
};

/** ShortUrl object type */
export type ShortUrl = {
  __typename: 'ShortUrl';
  id: Scalars['ID'];
  longUrl: Scalars['String'];
  shortUrl: Scalars['String'];
};

/** Sort direction type enum */
export enum SortDirectionType {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING'
}

/** Represents a single setup task */
export type Task = {
  __typename: 'Task';
  type: TaskType;
};

/** Represents the different types of tasks */
export enum TaskType {
  IpoNotifications = 'IPO_NOTIFICATIONS',
  MfaSetup = 'MFA_SETUP',
  PasswordChange = 'PASSWORD_CHANGE',
  UserAgreement = 'USER_AGREEMENT'
}

/** GraphQL enum type for ThemeVariantType */
export enum ThemeKind {
  Canalyst = 'CANALYST',
  Classic = 'CLASSIC'
}

/** Toggles a WatchListItem */
export type ToggleWatchListItem = {
  __typename: 'ToggleWatchListItem';
  watchListItem: WatchListItem;
};

export enum TokenType {
  Api = 'API',
  User = 'USER'
}


/** Update a user's email settings and IPO subscription preferences */
export type UpdateEmailSettings = {
  __typename: 'UpdateEmailSettings';
  user: User;
};

/** Mutation to update the MFA backup tokens for Me */
export type UpdateMeMfaBackupTokens = {
  __typename: 'UpdateMeMfaBackupTokens';
  mfaBackupTokens: Array<Scalars['String']>;
};

/**
 * Update the current user's password
 *
 * All Django sessions for the user will be invalidated when the user's password is
 * successfully changed. While JWTs will remain valid, if the application also relies
 * on Django session authentication it'd be a good idea to ask the user to log in
 * again right away. Otherwise the user will experience a surprise login form later
 * when session auth is required (e.g. if they try to access a legacy WPv1 page).
 */
export type UpdateMePassword = {
  __typename: 'UpdateMePassword';
  /** Whether or not the password was successfully changed */
  success: Scalars['Boolean'];
};

/** Represents update types for Resource objects */
export enum UpdateTypeKind {
  Partial = 'PARTIAL',
  Regular = 'REGULAR'
}

/**
 * Mutation to update user's a preferred default variant dimensions
 *
 * Note: If user ID omitted defaults to updating user making the request
 */
export type UpdateVariantPreference = {
  __typename: 'UpdateVariantPreference';
  user: User;
};

/** Updates the watch list for a File or FileLink */
export type UpdateWatchList = {
  __typename: 'UpdateWatchList';
  watchList?: Maybe<Array<User>>;
};

/** User object type */
export type User = {
  __typename: 'User';
  company?: Maybe<Scalars['String']>;
  djangoGlobalPerms: Array<Scalars['String']>;
  emailSettings?: Maybe<EmailSettings>;
  id: Scalars['ID'];
  mfaEnabledAuth0: Scalars['Boolean'];
  mfaEnforced: Scalars['Boolean'];
  mfaSetupComplete: Scalars['Boolean'];
  name: Scalars['String'];
  privateFolder: Scalars['String'];
  staff: Scalars['Boolean'];
  variantPreference: VariantPreference;
};

/**
 * Represents the user's setup state
 *
 * The Parent Value Object for this ObjectType is User. See
 * https://docs.graphene-python.org/en/latest/types/objecttypes/#resolverparamparent
 */
export type UserSetup = {
  __typename: 'UserSetup';
  /** If all tasks are complete */
  complete: Scalars['Boolean'];
  id: Scalars['ID'];
  /** Remaining tasks. Will be empty if complete is true and contain at least 1 task if complete is false */
  tasks: Array<Task>;
};

/** User default variants dimensions */
export type VariantPreference = {
  __typename: 'VariantPreference';
  driversWorksheets: DriversWorksheetsKind;
  periodOrder: PeriodOrderKind;
  theme: ThemeKind;
};

/**
 * Watch list item object type
 *
 * Corresponds to a FileFavourite object in the database
 */
export type WatchListItem = {
  __typename: 'WatchListItem';
  id: Scalars['ID'];
  resource: Resource;
  toggled: Scalars['Boolean'];
  user: User;
};

export type BulkUserCreateGroupsQueryVariables = Exact<{
  query: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
}>;


export type BulkUserCreateGroupsQuery = (
  { __typename: 'Query' }
  & { results?: Maybe<Array<(
    { __typename: 'Group' }
    & { value: Group['id'], display: Group['name'] }
  )>> }
);

export type CompaniesCompanyListQueryVariables = Exact<{
  lastUpdate?: Maybe<Scalars['DateTime']>;
}>;


export type CompaniesCompanyListQuery = (
  { __typename: 'Query' }
  & { companiesList: (
    { __typename: 'CompaniesList' }
    & Pick<CompaniesList, 'updated'>
    & { companies: Array<(
      { __typename: 'Company' }
      & Pick<Company, 'id' | 'name' | 'ticker' | 'updated' | 'updateType' | 'period' | 'revenue' | 'watchListed' | 'folderId'>
    )> }
  ) }
);

export type CompaniesCategoryListQueryVariables = Exact<{ [key: string]: never; }>;


export type CompaniesCategoryListQuery = (
  { __typename: 'Query' }
  & { categoryList: Array<(
    { __typename: 'Category' }
    & Pick<Category, 'id' | 'name' | 'parentId' | 'linkedFileIds'>
  )> }
);

export type CompaniesResourceQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;


export type CompaniesResourceQuery = (
  { __typename: 'Query' }
  & { file?: Maybe<(
    { __typename: 'Resource' }
    & CompanyResourceFragment
  )>, me: (
    { __typename: 'User' }
    & UserForDlFragment
  ) }
);

export type CompanyResourceFragment = (
  { __typename: 'Resource' }
  & Pick<Resource, 'icon' | 'id' | 'name' | 'slug' | 'kind' | 'updateEventDate' | 'updateTypeDisplay' | 'updated' | 'downloadUrl' | 'variantsExpected' | 'watchListed' | 'mostRecentQuarter' | 'fileType'>
  & { folder: (
    { __typename: 'Folder' }
    & Pick<Folder, 'id'>
  ), variants: Array<(
    { __typename: 'ResourceVariant' }
    & Pick<ResourceVariant, 'id' | 'description' | 'downloadUrl' | 'periodOrder' | 'driversWorksheets' | 'theme' | 'userPreferred'>
  )> }
);

export type ContactUsCreateSupportRequestMutationVariables = Exact<{
  companyOrTicker?: Maybe<Scalars['String']>;
  modelUpdateType?: Maybe<ModelUpdateType>;
  requestBody?: Maybe<Scalars['String']>;
  requestType: RequestKind;
}>;


export type ContactUsCreateSupportRequestMutation = (
  { __typename: 'Mutation' }
  & { createSupportRequest?: Maybe<(
    { __typename: 'CreateSupportRequest' }
    & Pick<CreateSupportRequest, 'success'>
  )> }
);

export type BrowseQueryVariables = Exact<{
  path: Scalars['String'];
  ordering?: Maybe<FolderItemOrdering>;
  reverseOrdering?: Maybe<Scalars['Boolean']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
}>;


export type BrowseQuery = (
  { __typename: 'Query' }
  & { folderByPath?: Maybe<(
    { __typename: 'Folder' }
    & Pick<Folder, 'id' | 'name' | 'path' | 'mePermissions' | 'folderTags' | 'placeholderCount' | 'resourceCount'>
    & { ancestorFolders: Array<(
      { __typename: 'Folder' }
      & Pick<Folder, 'id' | 'name' | 'path'>
    )>, items: (
      { __typename: 'FolderItemConnection' }
      & { pageInfo: (
        { __typename: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
      ), edges: Array<(
        { __typename: 'FolderItemEdge' }
        & BrowseFolderItemEdgeFragment
      )> }
    ) }
  )>, me: (
    { __typename: 'User' }
    & UserForDlFragment
  ) }
);

export type BrowseFolderItemEdgeFragment = (
  { __typename: 'FolderItemEdge' }
  & Pick<FolderItemEdge, 'cursor'>
  & { node: (
    { __typename: 'Folder' }
    & BrowseFolderFragment
  ) | (
    { __typename: 'Placeholder' }
    & BrowsePlaceholderFragment
  ) | (
    { __typename: 'Resource' }
    & BrowseResourceFragment
  ) }
);

export type BrowseFolderFragment = (
  { __typename: 'Folder' }
  & Pick<Folder, 'icon' | 'id' | 'itemCountRecursive' | 'name' | 'slug' | 'path'>
);

export type BrowsePlaceholderFragment = (
  { __typename: 'Placeholder' }
  & Pick<Placeholder, 'icon' | 'id' | 'name' | 'slug'>
);

export type BrowseResourceFragment = (
  { __typename: 'Resource' }
  & Pick<Resource, 'icon' | 'id' | 'name' | 'slug' | 'kind' | 'updateEventDate' | 'updateTypeDisplay' | 'updated' | 'downloadUrl' | 'variantsExpected' | 'watchListed' | 'mostRecentQuarter' | 'fileType' | 'isCustom'>
  & { folder: (
    { __typename: 'Folder' }
    & Pick<Folder, 'id'>
  ), variants: Array<(
    { __typename: 'ResourceVariant' }
    & Pick<ResourceVariant, 'id' | 'description' | 'downloadUrl' | 'periodOrder' | 'driversWorksheets' | 'theme' | 'userPreferred'>
  )> }
);

export type ResourceByPathAndSlugQueryVariables = Exact<{
  folderPath: Scalars['String'];
  fileSlug: Scalars['String'];
}>;


export type ResourceByPathAndSlugQuery = (
  { __typename: 'Query' }
  & { resourceByPathAndSlug?: Maybe<(
    { __typename: 'Resource' }
    & ResourceByPathAndSlugResourceFragment
  )> }
);

export type ResourceByPathAndSlugResourceFragment = (
  { __typename: 'Resource' }
  & Pick<Resource, 'id' | 'downloadUrl'>
);

export type ShareLinkQueryVariables = Exact<{
  id: Scalars['ID'];
  latestVersion: Scalars['Boolean'];
}>;


export type ShareLinkQuery = (
  { __typename: 'Query' }
  & { folderItem?: Maybe<(
    { __typename: 'Folder' }
    & ShareLinkFolderItem_Folder_Fragment
  ) | (
    { __typename: 'Placeholder' }
    & ShareLinkFolderItem_Placeholder_Fragment
  ) | (
    { __typename: 'Resource' }
    & ShareLinkFolderItem_Resource_Fragment
  )>, me: (
    { __typename: 'User' }
    & UserForDlFragment
  ) }
);

type ShareLinkFolderItem_Folder_Fragment = (
  { __typename: 'Folder' }
  & ShareLinkFolderFragment
);

type ShareLinkFolderItem_Placeholder_Fragment = (
  { __typename: 'Placeholder' }
  & ShareLinkPlaceholderFragment
);

type ShareLinkFolderItem_Resource_Fragment = (
  { __typename: 'Resource' }
  & ShareLinkResourceFragment
);

export type ShareLinkFolderItemFragment = ShareLinkFolderItem_Folder_Fragment | ShareLinkFolderItem_Placeholder_Fragment | ShareLinkFolderItem_Resource_Fragment;

export type ShareLinkFolderFragment = (
  { __typename: 'Folder' }
  & Pick<Folder, 'id' | 'path'>
);

export type ShareLinkPlaceholderFragment = (
  { __typename: 'Placeholder' }
  & Pick<Placeholder, 'id'>
  & { folder: (
    { __typename: 'Folder' }
    & Pick<Folder, 'id' | 'path'>
  ) }
);

export type ShareLinkResourceFragment = (
  { __typename: 'Resource' }
  & Pick<Resource, 'id' | 'name' | 'variantsExpected' | 'downloadUrl'>
  & { folder: (
    { __typename: 'Folder' }
    & Pick<Folder, 'id' | 'path'>
  ), variants: Array<(
    { __typename: 'ResourceVariant' }
    & Pick<ResourceVariant, 'id' | 'periodOrder' | 'driversWorksheets' | 'theme' | 'downloadUrl'>
  )> }
);

export type TegusLinkQueryVariables = Exact<{
  csin: Scalars['String'];
}>;


export type TegusLinkQuery = (
  { __typename: 'Query' }
  & { filesByFolderRecursive?: Maybe<(
    { __typename: 'ResourceConnection' }
    & { edges: Array<Maybe<(
      { __typename: 'ResourceEdge' }
      & { node?: Maybe<(
        { __typename: 'Resource' }
        & Pick<Resource, 'tegusLink'>
      )> }
    )>> }
  )> }
);

export type WhatsNewPromosQueryVariables = Exact<{
  count: Scalars['Int'];
}>;


export type WhatsNewPromosQuery = (
  { __typename: 'Query' }
  & { promos: Array<(
    { __typename: 'Promo' }
    & Pick<Promo, 'title' | 'description' | 'imageUrl' | 'imageAltText'>
    & { action: (
      { __typename: 'Action' }
      & Pick<Action, 'text' | 'link' | 'type'>
    ) }
  )> }
);

export type AddFolderModalCreateFolderMutationVariables = Exact<{
  folderName: Scalars['String'];
  parentFolderFiid: Scalars['String'];
}>;


export type AddFolderModalCreateFolderMutation = (
  { __typename: 'Mutation' }
  & { createFolder?: Maybe<(
    { __typename: 'CreateFolder' }
    & Pick<CreateFolder, 'folderName'>
  )> }
);

export type DeleteResourceModalDeleteResourceFileMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteResourceModalDeleteResourceFileMutation = (
  { __typename: 'Mutation' }
  & { deleteResourceFile?: Maybe<(
    { __typename: 'DeleteResourceFile' }
    & Pick<DeleteResourceFile, 'id'>
  )> }
);

export type WatchListModalUserSearchQueryVariables = Exact<{
  query: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
}>;


export type WatchListModalUserSearchQuery = (
  { __typename: 'Query' }
  & { results: Array<(
    { __typename: 'User' }
    & { value: User['id'], display: User['name'], subtext: User['company'] }
  )> }
);

export type WatchListModalUsersWatchingQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type WatchListModalUsersWatchingQuery = (
  { __typename: 'Query' }
  & { results: Array<(
    { __typename: 'User' }
    & { value: User['id'], display: User['name'], subtext: User['company'] }
  )> }
);

export type WatchListModalUpdateWatchListMutationVariables = Exact<{
  resourceId: Scalars['ID'];
  userIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type WatchListModalUpdateWatchListMutation = (
  { __typename: 'Mutation' }
  & { updateWatchList?: Maybe<(
    { __typename: 'UpdateWatchList' }
    & { results?: Maybe<Array<(
      { __typename: 'User' }
      & { value: User['id'], display: User['name'], subtext: User['company'] }
    )>> }
  )> }
);

export type VersionsQueryVariables = Exact<{
  id: Scalars['ID'];
  first: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
}>;


export type VersionsQuery = (
  { __typename: 'Query' }
  & { folderItem?: Maybe<{ __typename: 'Folder' } | { __typename: 'Placeholder' } | (
    { __typename: 'Resource' }
    & { allVersions: (
      { __typename: 'ResourceVersionConnection' }
      & { pageInfo: (
        { __typename: 'PageInfo' }
        & VersionPageInfoFragment
      ), edges: Array<(
        { __typename: 'ResourceVersionEdge' }
        & { node: (
          { __typename: 'Resource' }
          & VersionResourceFragment
        ) }
      )> }
    ) }
  )> }
);

export type VersionResourceFragment = (
  { __typename: 'Resource' }
  & Pick<Resource, 'id' | 'name' | 'created' | 'updated' | 'updateType' | 'version' | 'icon' | 'downloadUrl' | 'variantsExpected' | 'updateEventDate' | 'updateTypeDisplay' | 'slug' | 'mostRecentQuarter'>
  & { updatedBy: (
    { __typename: 'User' }
    & Pick<User, 'name'>
  ), variants: Array<(
    { __typename: 'ResourceVariant' }
    & Pick<ResourceVariant, 'id' | 'name' | 'description' | 'downloadUrl' | 'periodOrder' | 'driversWorksheets' | 'theme' | 'userPreferred'>
  )> }
);

export type VersionPageInfoFragment = (
  { __typename: 'PageInfo' }
  & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
);

export type SearchQueryVariables = Exact<{
  query: Scalars['String'];
  folder?: Maybe<Scalars['ID']>;
  first: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
  includeFromFolder: Scalars['Boolean'];
  fromFolder: Scalars['ID'];
  includeSearchFolder: Scalars['Boolean'];
  searchFolder: Scalars['ID'];
}>;


export type SearchQuery = (
  { __typename: 'Query' }
  & { search: (
    { __typename: 'SearchItemConnection' }
    & { pageInfo: (
      { __typename: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ), edges: Array<(
      { __typename: 'SearchItemEdge' }
      & SearchSearchItemEdgeFragment
    )> }
  ), searchFolder?: Maybe<(
    { __typename: 'Folder' }
    & SearchOrFromFolderFragment
  )>, fromFolder?: Maybe<(
    { __typename: 'Folder' }
    & SearchOrFromFolderFragment
  )>, me: (
    { __typename: 'User' }
    & UserForDlFragment
  ) }
);

export type SearchSearchItemEdgeFragment = (
  { __typename: 'SearchItemEdge' }
  & Pick<SearchItemEdge, 'cursor'>
  & { node: (
    { __typename: 'Folder' }
    & SearchFolderFragment
  ) | (
    { __typename: 'Placeholder' }
    & SearchPlaceholderFragment
  ) | (
    { __typename: 'Resource' }
    & SearchResourceFragment
  ) }
);

export type SearchFolderFragment = (
  { __typename: 'Folder' }
  & Pick<Folder, 'icon' | 'id' | 'itemCountRecursive' | 'name' | 'slug' | 'path'>
  & { parentFolder?: Maybe<(
    { __typename: 'Folder' }
    & SearchSearchItemFolderFragment
  )> }
);

export type SearchPlaceholderFragment = (
  { __typename: 'Placeholder' }
  & Pick<Placeholder, 'icon' | 'id' | 'name' | 'slug'>
  & { folder: (
    { __typename: 'Folder' }
    & SearchSearchItemFolderFragment
  ) }
);

export type SearchResourceFragment = (
  { __typename: 'Resource' }
  & Pick<Resource, 'icon' | 'id' | 'kind' | 'name' | 'slug' | 'updateEventDate' | 'updateTypeDisplay' | 'updated' | 'downloadUrl' | 'variantsExpected' | 'watchListed' | 'mostRecentQuarter' | 'fileType' | 'isCustom'>
  & { variants: Array<(
    { __typename: 'ResourceVariant' }
    & Pick<ResourceVariant, 'id' | 'description' | 'downloadUrl' | 'periodOrder' | 'driversWorksheets' | 'theme' | 'userPreferred'>
  )>, folder: (
    { __typename: 'Folder' }
    & SearchSearchItemFolderFragment
  ) }
);

export type SearchSearchItemFolderFragment = (
  { __typename: 'Folder' }
  & Pick<Folder, 'id' | 'name' | 'path'>
  & { ancestorFolders: Array<(
    { __typename: 'Folder' }
    & Pick<Folder, 'id' | 'name' | 'path'>
  )> }
);

export type SearchOrFromFolderFragment = (
  { __typename: 'Folder' }
  & Pick<Folder, 'id' | 'name'>
);

export type SideNavFolderByPathQueryVariables = Exact<{
  path: Scalars['String'];
}>;


export type SideNavFolderByPathQuery = (
  { __typename: 'Query' }
  & { folderByPath?: Maybe<(
    { __typename: 'Folder' }
    & Pick<Folder, 'id' | 'itemCountRecursive'>
  )> }
);

export type ResourceDisplayQueryVariables = Exact<{
  count: Scalars['Int'];
}>;


export type ResourceDisplayQuery = (
  { __typename: 'Query' }
  & { resourceDisplays: Array<(
    { __typename: 'ResourceDisplay' }
    & Pick<ResourceDisplay, 'title' | 'description' | 'imageAltText' | 'imageUrl' | 'resourceType' | 'seriesId' | 'downloadUrl' | 'fiid' | 'slug'>
  )> }
);

export type ResourcesResourcesQueryVariables = Exact<{
  toolkitAndGuidesId: Scalars['ID'];
  templatesId: Scalars['ID'];
  screensId: Scalars['ID'];
  toolkitAndGuidesOrder: FolderItemOrdering;
  defaultOrder: FolderItemOrdering;
  numItems: Scalars['Int'];
}>;


export type ResourcesResourcesQuery = (
  { __typename: 'Query' }
  & { toolkitAndGuides?: Maybe<(
    { __typename: 'Folder' }
    & Pick<Folder, 'id'>
    & { items: (
      { __typename: 'FolderItemConnection' }
      & { edges: Array<(
        { __typename: 'FolderItemEdge' }
        & { node: { __typename: 'Folder' } | { __typename: 'Placeholder' } | (
          { __typename: 'Resource' }
          & ResourcesResourceFragment
        ) }
      )> }
    ) }
  )>, templates?: Maybe<(
    { __typename: 'Folder' }
    & Pick<Folder, 'id'>
    & { items: (
      { __typename: 'FolderItemConnection' }
      & { edges: Array<(
        { __typename: 'FolderItemEdge' }
        & { node: { __typename: 'Folder' } | { __typename: 'Placeholder' } | (
          { __typename: 'Resource' }
          & ResourcesResourceFragment
        ) }
      )> }
    ) }
  )>, screens?: Maybe<(
    { __typename: 'Folder' }
    & Pick<Folder, 'id'>
    & { items: (
      { __typename: 'FolderItemConnection' }
      & { edges: Array<(
        { __typename: 'FolderItemEdge' }
        & { node: { __typename: 'Folder' } | { __typename: 'Placeholder' } | (
          { __typename: 'Resource' }
          & ResourcesResourceFragment
        ) }
      )> }
    ) }
  )> }
);

export type ResourcesResourceFragment = (
  { __typename: 'Resource' }
  & Pick<Resource, 'name' | 'slug' | 'downloadUrl' | 'id' | 'seriesId'>
);

export type ResourcesFullResourcesQueryVariables = Exact<{
  folderId: Scalars['ID'];
  order: FolderItemOrdering;
  numItems: Scalars['Int'];
}>;


export type ResourcesFullResourcesQuery = (
  { __typename: 'Query' }
  & { folder?: Maybe<(
    { __typename: 'Folder' }
    & Pick<Folder, 'id'>
    & { items: (
      { __typename: 'FolderItemConnection' }
      & { edges: Array<(
        { __typename: 'FolderItemEdge' }
        & { node: { __typename: 'Folder' } | { __typename: 'Placeholder' } | (
          { __typename: 'Resource' }
          & ResourcesResourceFragment
        ) }
      )> }
    ) }
  )> }
);

export type SearchDropdownQueryVariables = Exact<{
  query: Scalars['String'];
  first: Scalars['Int'];
}>;


export type SearchDropdownQuery = (
  { __typename: 'Query' }
  & { search: (
    { __typename: 'SearchItemConnection' }
    & { pageInfo: (
      { __typename: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ), edges: Array<(
      { __typename: 'SearchItemEdge' }
      & SearchDropdownSearchItemEdgeFragment
    )> }
  ), me: (
    { __typename: 'User' }
    & UserForDlFragment
  ) }
);

export type SearchDropdownSearchItemEdgeFragment = (
  { __typename: 'SearchItemEdge' }
  & Pick<SearchItemEdge, 'cursor'>
  & { node: (
    { __typename: 'Folder' }
    & SearchDropdownFolderFragment
  ) | (
    { __typename: 'Placeholder' }
    & SearchDropdownPlaceholderFragment
  ) | (
    { __typename: 'Resource' }
    & SearchDropdownResourceFragment
  ) }
);

export type SearchDropdownFolderFragment = (
  { __typename: 'Folder' }
  & Pick<Folder, 'icon' | 'id' | 'name' | 'path'>
  & { parentFolder?: Maybe<(
    { __typename: 'Folder' }
    & Pick<Folder, 'id' | 'name' | 'path'>
  )> }
);

export type SearchDropdownPlaceholderFragment = (
  { __typename: 'Placeholder' }
  & Pick<Placeholder, 'icon' | 'id' | 'name'>
  & { folder: (
    { __typename: 'Folder' }
    & Pick<Folder, 'id' | 'name' | 'path'>
  ) }
);

export type SearchDropdownResourceFragment = (
  { __typename: 'Resource' }
  & Pick<Resource, 'icon' | 'id' | 'name' | 'downloadUrl' | 'variantsExpected'>
  & { folder: (
    { __typename: 'Folder' }
    & Pick<Folder, 'id' | 'name' | 'path'>
  ), variants: Array<(
    { __typename: 'ResourceVariant' }
    & Pick<ResourceVariant, 'id' | 'description' | 'downloadUrl' | 'periodOrder' | 'theme' | 'driversWorksheets' | 'userPreferred'>
  )> }
);

export type CreateShortUrlMutationVariables = Exact<{
  longUrl: Scalars['String'];
}>;


export type CreateShortUrlMutation = (
  { __typename: 'Mutation' }
  & { createShortUrl?: Maybe<(
    { __typename: 'CreateShortUrl' }
    & { shortUrl: (
      { __typename: 'ShortUrl' }
      & MyShortUrlsShortUrlFragment
    ) }
  )> }
);

export type MyShortUrlsQueryVariables = Exact<{
  count: Scalars['Int'];
}>;


export type MyShortUrlsQuery = (
  { __typename: 'Query' }
  & { myShortUrls: Array<(
    { __typename: 'ShortUrl' }
    & MyShortUrlsShortUrlFragment
  )> }
);

export type MyShortUrlsShortUrlFragment = (
  { __typename: 'ShortUrl' }
  & Pick<ShortUrl, 'id' | 'longUrl' | 'shortUrl'>
);

export type UserSettingsMeQueryVariables = Exact<{ [key: string]: never; }>;


export type UserSettingsMeQuery = (
  { __typename: 'Query' }
  & { me: (
    { __typename: 'User' }
    & Pick<User, 'id' | 'djangoGlobalPerms' | 'mfaSetupComplete'>
  ) }
);

export type ApiTokensDeleteDeleteTokenMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ApiTokensDeleteDeleteTokenMutation = (
  { __typename: 'Mutation' }
  & { deleteApiToken?: Maybe<(
    { __typename: 'DeleteApiToken' }
    & Pick<DeleteApiToken, 'id'>
  )> }
);

export type EmailSettingsMeQueryVariables = Exact<{ [key: string]: never; }>;


export type EmailSettingsMeQuery = (
  { __typename: 'Query' }
  & { me: (
    { __typename: 'User' }
    & Pick<User, 'id'>
    & { emailSettings?: Maybe<(
      { __typename: 'EmailSettings' }
      & Pick<EmailSettings, 'emailRecentDownloadUpdate'>
      & { ipoSubscriptions: (
        { __typename: 'IpoSubscriptions' }
        & Pick<IpoSubscriptions, 'allFolders'>
        & { folders: Array<(
          { __typename: 'IpoSubscriptionFolder' }
          & Pick<IpoSubscriptionFolder, 'id' | 'isChecked' | 'name' | 'sector'>
        )> }
      ) }
    )> }
  ) }
);

export type EmailSettingsUpdateEmailSettingsMutationVariables = Exact<{
  emailRecentDownloadUpdate?: Maybe<Scalars['Boolean']>;
  ipoSubscriptions?: Maybe<IpoSubscriptionInput>;
}>;


export type EmailSettingsUpdateEmailSettingsMutation = (
  { __typename: 'Mutation' }
  & { updateEmailSettings?: Maybe<(
    { __typename: 'UpdateEmailSettings' }
    & { user: (
      { __typename: 'User' }
      & Pick<User, 'id'>
      & { emailSettings?: Maybe<(
        { __typename: 'EmailSettings' }
        & Pick<EmailSettings, 'emailRecentDownloadUpdate'>
        & { ipoSubscriptions: (
          { __typename: 'IpoSubscriptions' }
          & Pick<IpoSubscriptions, 'allFolders'>
          & { folders: Array<(
            { __typename: 'IpoSubscriptionFolder' }
            & Pick<IpoSubscriptionFolder, 'id' | 'isChecked' | 'name' | 'sector'>
          )> }
        ) }
      )> }
    ) }
  )> }
);

export type BackupTokensMyMfaBackupTokensQueryVariables = Exact<{ [key: string]: never; }>;


export type BackupTokensMyMfaBackupTokensQuery = (
  { __typename: 'Query' }
  & Pick<Query, 'myMfaBackupTokens'>
);

export type BackupTokensUpdateMfaBackupTokensMutationVariables = Exact<{ [key: string]: never; }>;


export type BackupTokensUpdateMfaBackupTokensMutation = (
  { __typename: 'Mutation' }
  & { updateMfaBackupTokens?: Maybe<(
    { __typename: 'UpdateMeMfaBackupTokens' }
    & Pick<UpdateMeMfaBackupTokens, 'mfaBackupTokens'>
  )> }
);

export type DisableMfaButtonDisableMfaMutationVariables = Exact<{
  userId?: Maybe<Scalars['ID']>;
}>;


export type DisableMfaButtonDisableMfaMutation = (
  { __typename: 'Mutation' }
  & { disableMfa?: Maybe<(
    { __typename: 'DisableMfa' }
    & Pick<DisableMfa, 'success'>
  )> }
);

export type MfaInfoMeQueryVariables = Exact<{ [key: string]: never; }>;


export type MfaInfoMeQuery = (
  { __typename: 'Query' }
  & { me: (
    { __typename: 'User' }
    & Pick<User, 'mfaEnforced'>
  ) }
);

export type MfaSettingsMeQueryVariables = Exact<{ [key: string]: never; }>;


export type MfaSettingsMeQuery = (
  { __typename: 'Query' }
  & { me: (
    { __typename: 'User' }
    & Pick<User, 'id' | 'mfaEnforced' | 'mfaSetupComplete'>
  ) }
);

export type MfaSettingsAuth0RegenerateRecoveryTokenMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type MfaSettingsAuth0RegenerateRecoveryTokenMutation = (
  { __typename: 'Mutation' }
  & { regenerateMfaRecoveryTokenAuth0?: Maybe<(
    { __typename: 'RegenerateMfaRecoveryToken' }
    & Pick<RegenerateMfaRecoveryToken, 'token'>
  )> }
);

export type MfaSettingsAuth0ResetMfaMutationVariables = Exact<{
  userId: Scalars['ID'];
  generateTicket?: Maybe<Scalars['Boolean']>;
}>;


export type MfaSettingsAuth0ResetMfaMutation = (
  { __typename: 'Mutation' }
  & { resetMfaAuth0?: Maybe<(
    { __typename: 'ResetMfa' }
    & Pick<ResetMfa, 'success' | 'url'>
  )> }
);

export type MfaSettingsAuth0SetUpMfaMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type MfaSettingsAuth0SetUpMfaMutation = (
  { __typename: 'Mutation' }
  & { setUpMfaAuth0?: Maybe<(
    { __typename: 'CreateMfaEnrollmentUrl' }
    & Pick<CreateMfaEnrollmentUrl, 'url'>
  )> }
);

export type MfaSetupFormEnableMfaMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type MfaSetupFormEnableMfaMutation = (
  { __typename: 'Mutation' }
  & { enableMfa?: Maybe<(
    { __typename: 'EnableMeMfa' }
    & { user: (
      { __typename: 'User' }
      & Pick<User, 'id'>
    ) }
  )> }
);

export type MfaSetupPageMeQueryVariables = Exact<{ [key: string]: never; }>;


export type MfaSetupPageMeQuery = (
  { __typename: 'Query' }
  & { me: (
    { __typename: 'User' }
    & Pick<User, 'mfaSetupComplete'>
  ) }
);

export type MfaSetupStepsMyMfaSetupUrlQueryVariables = Exact<{ [key: string]: never; }>;


export type MfaSetupStepsMyMfaSetupUrlQuery = (
  { __typename: 'Query' }
  & Pick<Query, 'myMfaSetupUrl'>
);

export type UpdatePasswordMutationMutationVariables = Exact<{
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
  newPasswordConfirmed: Scalars['String'];
}>;


export type UpdatePasswordMutationMutation = (
  { __typename: 'Mutation' }
  & { updatePassword?: Maybe<(
    { __typename: 'UpdateMePassword' }
    & Pick<UpdateMePassword, 'success'>
  )> }
);

export type UpdatePasswordCreateAuth0PasswordResetTicketMutationVariables = Exact<{
  userId?: Maybe<Scalars['ID']>;
}>;


export type UpdatePasswordCreateAuth0PasswordResetTicketMutation = (
  { __typename: 'Mutation' }
  & { createAuth0PasswordResetToken?: Maybe<(
    { __typename: 'CreatePasswordResetTicket' }
    & Pick<CreatePasswordResetTicket, 'ticket'>
  )> }
);

export type VariantPreferenceMeQueryVariables = Exact<{ [key: string]: never; }>;


export type VariantPreferenceMeQuery = (
  { __typename: 'Query' }
  & { me: (
    { __typename: 'User' }
    & Pick<User, 'id' | 'djangoGlobalPerms'>
    & { variantPreference: (
      { __typename: 'VariantPreference' }
      & Pick<VariantPreference, 'periodOrder' | 'driversWorksheets' | 'theme'>
    ) }
  ) }
);

export type VariantPreferenceUpdateUserVariantPreferenceMutationVariables = Exact<{
  periodOrder: PeriodOrderKind;
  driversWorksheets: DriversWorksheetsKind;
  theme: ThemeKind;
}>;


export type VariantPreferenceUpdateUserVariantPreferenceMutation = (
  { __typename: 'Mutation' }
  & { updateVariantPreference?: Maybe<(
    { __typename: 'UpdateVariantPreference' }
    & { user: (
      { __typename: 'User' }
      & Pick<User, 'id'>
      & { variantPreference: (
        { __typename: 'VariantPreference' }
        & Pick<VariantPreference, 'periodOrder' | 'driversWorksheets' | 'theme'>
      ) }
    ) }
  )> }
);

export type UserSetupUserSetupQueryVariables = Exact<{ [key: string]: never; }>;


export type UserSetupUserSetupQuery = (
  { __typename: 'Query' }
  & { userSetup: (
    { __typename: 'UserSetup' }
    & Pick<UserSetup, 'id' | 'complete'>
    & { tasks: Array<(
      { __typename: 'Task' }
      & UserSetupTaskFragment
    )> }
  ) }
);

export type UserSetupTaskFragment = (
  { __typename: 'Task' }
  & Pick<Task, 'type'>
);

export type UserSetupAcceptUserAgreementMutationVariables = Exact<{ [key: string]: never; }>;


export type UserSetupAcceptUserAgreementMutation = (
  { __typename: 'Mutation' }
  & { acceptUserAgreement?: Maybe<(
    { __typename: 'AcceptUserAgreement' }
    & Pick<AcceptUserAgreement, 'success'>
  )> }
);

export type UserForDlFragment = (
  { __typename: 'User' }
  & Pick<User, 'id' | 'djangoGlobalPerms' | 'staff'>
  & { variantPreference: (
    { __typename: 'VariantPreference' }
    & Pick<VariantPreference, 'periodOrder' | 'driversWorksheets' | 'theme'>
  ) }
);

export const CompanyResourceFragmentDoc = gql`
    fragment CompanyResource on Resource {
  icon
  id
  name
  slug
  kind
  updateEventDate
  updateTypeDisplay
  updated
  downloadUrl
  folder {
    id
  }
  variants {
    id
    description
    downloadUrl
    periodOrder
    driversWorksheets
    theme
    userPreferred
  }
  variantsExpected
  watchListed
  mostRecentQuarter
  fileType
}
    `;
export const BrowseFolderFragmentDoc = gql`
    fragment BrowseFolder on Folder {
  icon
  id
  itemCountRecursive
  name
  slug
  path
}
    `;
export const BrowsePlaceholderFragmentDoc = gql`
    fragment BrowsePlaceholder on Placeholder {
  icon
  id
  name
  slug
}
    `;
export const BrowseResourceFragmentDoc = gql`
    fragment BrowseResource on Resource {
  icon
  id
  name
  slug
  kind
  updateEventDate
  updateTypeDisplay
  updated
  downloadUrl
  folder {
    id
  }
  variants {
    id
    description
    downloadUrl
    periodOrder
    driversWorksheets
    theme
    userPreferred
  }
  variantsExpected
  watchListed
  mostRecentQuarter
  fileType
  isCustom
}
    `;
export const BrowseFolderItemEdgeFragmentDoc = gql`
    fragment BrowseFolderItemEdge on FolderItemEdge {
  cursor
  node {
    ...BrowseFolder
    ...BrowsePlaceholder
    ...BrowseResource
  }
}
    ${BrowseFolderFragmentDoc}
${BrowsePlaceholderFragmentDoc}
${BrowseResourceFragmentDoc}`;
export const ResourceByPathAndSlugResourceFragmentDoc = gql`
    fragment ResourceByPathAndSlugResource on Resource {
  id
  downloadUrl
}
    `;
export const ShareLinkFolderFragmentDoc = gql`
    fragment ShareLinkFolder on Folder {
  id
  path
}
    `;
export const ShareLinkPlaceholderFragmentDoc = gql`
    fragment ShareLinkPlaceholder on Placeholder {
  id
  folder {
    id
    path
  }
}
    `;
export const ShareLinkResourceFragmentDoc = gql`
    fragment ShareLinkResource on Resource {
  id
  name
  variantsExpected
  downloadUrl
  folder {
    id
    path
  }
  variants {
    id
    periodOrder
    driversWorksheets
    theme
    downloadUrl
  }
}
    `;
export const ShareLinkFolderItemFragmentDoc = gql`
    fragment ShareLinkFolderItem on FolderItem {
  ...ShareLinkFolder
  ...ShareLinkPlaceholder
  ...ShareLinkResource
}
    ${ShareLinkFolderFragmentDoc}
${ShareLinkPlaceholderFragmentDoc}
${ShareLinkResourceFragmentDoc}`;
export const VersionResourceFragmentDoc = gql`
    fragment VersionResource on Resource {
  id
  name
  created
  updated
  updateType
  updatedBy {
    name
  }
  version
  icon
  downloadUrl
  variantsExpected
  variants {
    id
    name
    description
    downloadUrl
    periodOrder
    driversWorksheets
    theme
    userPreferred
  }
  updateEventDate
  updateTypeDisplay
  slug
  mostRecentQuarter
}
    `;
export const VersionPageInfoFragmentDoc = gql`
    fragment VersionPageInfo on PageInfo {
  hasNextPage
  hasPreviousPage
  startCursor
  endCursor
}
    `;
export const SearchSearchItemFolderFragmentDoc = gql`
    fragment SearchSearchItemFolder on Folder {
  id
  name
  path
  ancestorFolders {
    id
    name
    path
  }
}
    `;
export const SearchFolderFragmentDoc = gql`
    fragment SearchFolder on Folder {
  icon
  id
  itemCountRecursive
  name
  slug
  path
  parentFolder {
    ...SearchSearchItemFolder
  }
}
    ${SearchSearchItemFolderFragmentDoc}`;
export const SearchPlaceholderFragmentDoc = gql`
    fragment SearchPlaceholder on Placeholder {
  icon
  id
  name
  slug
  folder {
    ...SearchSearchItemFolder
  }
}
    ${SearchSearchItemFolderFragmentDoc}`;
export const SearchResourceFragmentDoc = gql`
    fragment SearchResource on Resource {
  icon
  id
  kind
  name
  slug
  updateEventDate
  updateTypeDisplay
  updated
  downloadUrl
  variants {
    id
    description
    downloadUrl
    periodOrder
    driversWorksheets
    theme
    userPreferred
  }
  variantsExpected
  folder {
    ...SearchSearchItemFolder
  }
  watchListed
  mostRecentQuarter
  fileType
  isCustom
}
    ${SearchSearchItemFolderFragmentDoc}`;
export const SearchSearchItemEdgeFragmentDoc = gql`
    fragment SearchSearchItemEdge on SearchItemEdge {
  cursor
  node {
    ...SearchFolder
    ...SearchPlaceholder
    ...SearchResource
  }
}
    ${SearchFolderFragmentDoc}
${SearchPlaceholderFragmentDoc}
${SearchResourceFragmentDoc}`;
export const SearchOrFromFolderFragmentDoc = gql`
    fragment SearchOrFromFolder on Folder {
  id
  name
}
    `;
export const ResourcesResourceFragmentDoc = gql`
    fragment ResourcesResource on Resource {
  name
  slug
  downloadUrl
  id
  seriesId
}
    `;
export const SearchDropdownFolderFragmentDoc = gql`
    fragment SearchDropdownFolder on Folder {
  icon
  id
  name
  path
  parentFolder {
    id
    name
    path
  }
}
    `;
export const SearchDropdownPlaceholderFragmentDoc = gql`
    fragment SearchDropdownPlaceholder on Placeholder {
  icon
  id
  name
  folder {
    id
    name
    path
  }
}
    `;
export const SearchDropdownResourceFragmentDoc = gql`
    fragment SearchDropdownResource on Resource {
  icon
  id
  name
  downloadUrl
  folder {
    id
    name
    path
  }
  variants {
    id
    description
    downloadUrl
    periodOrder
    theme
    driversWorksheets
    userPreferred
  }
  variantsExpected
}
    `;
export const SearchDropdownSearchItemEdgeFragmentDoc = gql`
    fragment SearchDropdownSearchItemEdge on SearchItemEdge {
  cursor
  node {
    ...SearchDropdownFolder
    ...SearchDropdownPlaceholder
    ...SearchDropdownResource
  }
}
    ${SearchDropdownFolderFragmentDoc}
${SearchDropdownPlaceholderFragmentDoc}
${SearchDropdownResourceFragmentDoc}`;
export const MyShortUrlsShortUrlFragmentDoc = gql`
    fragment MyShortUrlsShortUrl on ShortUrl {
  id
  longUrl
  shortUrl
}
    `;
export const UserSetupTaskFragmentDoc = gql`
    fragment UserSetupTask on Task {
  type
}
    `;
export const UserForDlFragmentDoc = gql`
    fragment UserForDl on User {
  id
  variantPreference {
    periodOrder
    driversWorksheets
    theme
  }
  djangoGlobalPerms
  staff
}
    `;
export const BulkUserCreateGroupsDocument = gql`
    query BulkUserCreateGroups($query: String!, $first: Int) {
  results: groups(query: $query, first: $first) {
    value: id
    display: name
  }
}
    `;

/**
 * __useBulkUserCreateGroupsQuery__
 *
 * To run a query within a React component, call `useBulkUserCreateGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBulkUserCreateGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBulkUserCreateGroupsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useBulkUserCreateGroupsQuery(baseOptions: Apollo.QueryHookOptions<BulkUserCreateGroupsQuery, BulkUserCreateGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BulkUserCreateGroupsQuery, BulkUserCreateGroupsQueryVariables>(BulkUserCreateGroupsDocument, options);
      }
export function useBulkUserCreateGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BulkUserCreateGroupsQuery, BulkUserCreateGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BulkUserCreateGroupsQuery, BulkUserCreateGroupsQueryVariables>(BulkUserCreateGroupsDocument, options);
        }
export type BulkUserCreateGroupsQueryHookResult = ReturnType<typeof useBulkUserCreateGroupsQuery>;
export type BulkUserCreateGroupsLazyQueryHookResult = ReturnType<typeof useBulkUserCreateGroupsLazyQuery>;
export type BulkUserCreateGroupsQueryResult = Apollo.QueryResult<BulkUserCreateGroupsQuery, BulkUserCreateGroupsQueryVariables>;
export function refetchBulkUserCreateGroupsQuery(variables?: BulkUserCreateGroupsQueryVariables) {
      return { query: BulkUserCreateGroupsDocument, variables: variables }
    }
export const CompaniesCompanyListDocument = gql`
    query CompaniesCompanyList($lastUpdate: DateTime) {
  companiesList(lastUpdate: $lastUpdate) {
    updated
    companies {
      id
      name
      ticker
      updated
      updateType
      period
      revenue
      watchListed
      folderId
    }
  }
}
    `;

/**
 * __useCompaniesCompanyListQuery__
 *
 * To run a query within a React component, call `useCompaniesCompanyListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesCompanyListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesCompanyListQuery({
 *   variables: {
 *      lastUpdate: // value for 'lastUpdate'
 *   },
 * });
 */
export function useCompaniesCompanyListQuery(baseOptions?: Apollo.QueryHookOptions<CompaniesCompanyListQuery, CompaniesCompanyListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompaniesCompanyListQuery, CompaniesCompanyListQueryVariables>(CompaniesCompanyListDocument, options);
      }
export function useCompaniesCompanyListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompaniesCompanyListQuery, CompaniesCompanyListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompaniesCompanyListQuery, CompaniesCompanyListQueryVariables>(CompaniesCompanyListDocument, options);
        }
export type CompaniesCompanyListQueryHookResult = ReturnType<typeof useCompaniesCompanyListQuery>;
export type CompaniesCompanyListLazyQueryHookResult = ReturnType<typeof useCompaniesCompanyListLazyQuery>;
export type CompaniesCompanyListQueryResult = Apollo.QueryResult<CompaniesCompanyListQuery, CompaniesCompanyListQueryVariables>;
export function refetchCompaniesCompanyListQuery(variables?: CompaniesCompanyListQueryVariables) {
      return { query: CompaniesCompanyListDocument, variables: variables }
    }
export const CompaniesCategoryListDocument = gql`
    query CompaniesCategoryList {
  categoryList {
    id
    name
    parentId
    linkedFileIds
  }
}
    `;

/**
 * __useCompaniesCategoryListQuery__
 *
 * To run a query within a React component, call `useCompaniesCategoryListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesCategoryListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesCategoryListQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompaniesCategoryListQuery(baseOptions?: Apollo.QueryHookOptions<CompaniesCategoryListQuery, CompaniesCategoryListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompaniesCategoryListQuery, CompaniesCategoryListQueryVariables>(CompaniesCategoryListDocument, options);
      }
export function useCompaniesCategoryListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompaniesCategoryListQuery, CompaniesCategoryListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompaniesCategoryListQuery, CompaniesCategoryListQueryVariables>(CompaniesCategoryListDocument, options);
        }
export type CompaniesCategoryListQueryHookResult = ReturnType<typeof useCompaniesCategoryListQuery>;
export type CompaniesCategoryListLazyQueryHookResult = ReturnType<typeof useCompaniesCategoryListLazyQuery>;
export type CompaniesCategoryListQueryResult = Apollo.QueryResult<CompaniesCategoryListQuery, CompaniesCategoryListQueryVariables>;
export function refetchCompaniesCategoryListQuery(variables?: CompaniesCategoryListQueryVariables) {
      return { query: CompaniesCategoryListDocument, variables: variables }
    }
export const CompaniesResourceDocument = gql`
    query CompaniesResource($id: ID) {
  file(id: $id) {
    ...CompanyResource
  }
  me {
    ...UserForDl
  }
}
    ${CompanyResourceFragmentDoc}
${UserForDlFragmentDoc}`;

/**
 * __useCompaniesResourceQuery__
 *
 * To run a query within a React component, call `useCompaniesResourceQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesResourceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesResourceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompaniesResourceQuery(baseOptions?: Apollo.QueryHookOptions<CompaniesResourceQuery, CompaniesResourceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompaniesResourceQuery, CompaniesResourceQueryVariables>(CompaniesResourceDocument, options);
      }
export function useCompaniesResourceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompaniesResourceQuery, CompaniesResourceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompaniesResourceQuery, CompaniesResourceQueryVariables>(CompaniesResourceDocument, options);
        }
export type CompaniesResourceQueryHookResult = ReturnType<typeof useCompaniesResourceQuery>;
export type CompaniesResourceLazyQueryHookResult = ReturnType<typeof useCompaniesResourceLazyQuery>;
export type CompaniesResourceQueryResult = Apollo.QueryResult<CompaniesResourceQuery, CompaniesResourceQueryVariables>;
export function refetchCompaniesResourceQuery(variables?: CompaniesResourceQueryVariables) {
      return { query: CompaniesResourceDocument, variables: variables }
    }
export const ContactUsCreateSupportRequestDocument = gql`
    mutation ContactUsCreateSupportRequest($companyOrTicker: String, $modelUpdateType: ModelUpdateType, $requestBody: String, $requestType: RequestKind!) {
  createSupportRequest(
    companyOrTicker: $companyOrTicker
    requestBody: $requestBody
    requestType: $requestType
    modelUpdateType: $modelUpdateType
  ) {
    success
  }
}
    `;
export type ContactUsCreateSupportRequestMutationFn = Apollo.MutationFunction<ContactUsCreateSupportRequestMutation, ContactUsCreateSupportRequestMutationVariables>;

/**
 * __useContactUsCreateSupportRequestMutation__
 *
 * To run a mutation, you first call `useContactUsCreateSupportRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContactUsCreateSupportRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contactUsCreateSupportRequestMutation, { data, loading, error }] = useContactUsCreateSupportRequestMutation({
 *   variables: {
 *      companyOrTicker: // value for 'companyOrTicker'
 *      modelUpdateType: // value for 'modelUpdateType'
 *      requestBody: // value for 'requestBody'
 *      requestType: // value for 'requestType'
 *   },
 * });
 */
export function useContactUsCreateSupportRequestMutation(baseOptions?: Apollo.MutationHookOptions<ContactUsCreateSupportRequestMutation, ContactUsCreateSupportRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ContactUsCreateSupportRequestMutation, ContactUsCreateSupportRequestMutationVariables>(ContactUsCreateSupportRequestDocument, options);
      }
export type ContactUsCreateSupportRequestMutationHookResult = ReturnType<typeof useContactUsCreateSupportRequestMutation>;
export type ContactUsCreateSupportRequestMutationResult = Apollo.MutationResult<ContactUsCreateSupportRequestMutation>;
export type ContactUsCreateSupportRequestMutationOptions = Apollo.BaseMutationOptions<ContactUsCreateSupportRequestMutation, ContactUsCreateSupportRequestMutationVariables>;
export const BrowseDocument = gql`
    query Browse($path: String!, $ordering: FolderItemOrdering, $reverseOrdering: Boolean, $first: Int, $last: Int, $after: String, $before: String) {
  folderByPath(path: $path) {
    id
    name
    path
    ancestorFolders {
      id
      name
      path
    }
    items(
      ordering: $ordering
      reverseOrdering: $reverseOrdering
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        ...BrowseFolderItemEdge
      }
    }
    mePermissions
    folderTags
    placeholderCount
    resourceCount
  }
  me {
    ...UserForDl
  }
}
    ${BrowseFolderItemEdgeFragmentDoc}
${UserForDlFragmentDoc}`;

/**
 * __useBrowseQuery__
 *
 * To run a query within a React component, call `useBrowseQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrowseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrowseQuery({
 *   variables: {
 *      path: // value for 'path'
 *      ordering: // value for 'ordering'
 *      reverseOrdering: // value for 'reverseOrdering'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useBrowseQuery(baseOptions: Apollo.QueryHookOptions<BrowseQuery, BrowseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrowseQuery, BrowseQueryVariables>(BrowseDocument, options);
      }
export function useBrowseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrowseQuery, BrowseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrowseQuery, BrowseQueryVariables>(BrowseDocument, options);
        }
export type BrowseQueryHookResult = ReturnType<typeof useBrowseQuery>;
export type BrowseLazyQueryHookResult = ReturnType<typeof useBrowseLazyQuery>;
export type BrowseQueryResult = Apollo.QueryResult<BrowseQuery, BrowseQueryVariables>;
export function refetchBrowseQuery(variables?: BrowseQueryVariables) {
      return { query: BrowseDocument, variables: variables }
    }
export const ResourceByPathAndSlugDocument = gql`
    query resourceByPathAndSlug($folderPath: String!, $fileSlug: String!) {
  resourceByPathAndSlug(folderPath: $folderPath, fileSlug: $fileSlug) {
    ...ResourceByPathAndSlugResource
  }
}
    ${ResourceByPathAndSlugResourceFragmentDoc}`;

/**
 * __useResourceByPathAndSlugQuery__
 *
 * To run a query within a React component, call `useResourceByPathAndSlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourceByPathAndSlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourceByPathAndSlugQuery({
 *   variables: {
 *      folderPath: // value for 'folderPath'
 *      fileSlug: // value for 'fileSlug'
 *   },
 * });
 */
export function useResourceByPathAndSlugQuery(baseOptions: Apollo.QueryHookOptions<ResourceByPathAndSlugQuery, ResourceByPathAndSlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResourceByPathAndSlugQuery, ResourceByPathAndSlugQueryVariables>(ResourceByPathAndSlugDocument, options);
      }
export function useResourceByPathAndSlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResourceByPathAndSlugQuery, ResourceByPathAndSlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResourceByPathAndSlugQuery, ResourceByPathAndSlugQueryVariables>(ResourceByPathAndSlugDocument, options);
        }
export type ResourceByPathAndSlugQueryHookResult = ReturnType<typeof useResourceByPathAndSlugQuery>;
export type ResourceByPathAndSlugLazyQueryHookResult = ReturnType<typeof useResourceByPathAndSlugLazyQuery>;
export type ResourceByPathAndSlugQueryResult = Apollo.QueryResult<ResourceByPathAndSlugQuery, ResourceByPathAndSlugQueryVariables>;
export function refetchResourceByPathAndSlugQuery(variables?: ResourceByPathAndSlugQueryVariables) {
      return { query: ResourceByPathAndSlugDocument, variables: variables }
    }
export const ShareLinkDocument = gql`
    query ShareLink($id: ID!, $latestVersion: Boolean!) {
  folderItem(id: $id, latestVersion: $latestVersion) {
    ...ShareLinkFolderItem
  }
  me {
    ...UserForDl
  }
}
    ${ShareLinkFolderItemFragmentDoc}
${UserForDlFragmentDoc}`;

/**
 * __useShareLinkQuery__
 *
 * To run a query within a React component, call `useShareLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useShareLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShareLinkQuery({
 *   variables: {
 *      id: // value for 'id'
 *      latestVersion: // value for 'latestVersion'
 *   },
 * });
 */
export function useShareLinkQuery(baseOptions: Apollo.QueryHookOptions<ShareLinkQuery, ShareLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShareLinkQuery, ShareLinkQueryVariables>(ShareLinkDocument, options);
      }
export function useShareLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShareLinkQuery, ShareLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShareLinkQuery, ShareLinkQueryVariables>(ShareLinkDocument, options);
        }
export type ShareLinkQueryHookResult = ReturnType<typeof useShareLinkQuery>;
export type ShareLinkLazyQueryHookResult = ReturnType<typeof useShareLinkLazyQuery>;
export type ShareLinkQueryResult = Apollo.QueryResult<ShareLinkQuery, ShareLinkQueryVariables>;
export function refetchShareLinkQuery(variables?: ShareLinkQueryVariables) {
      return { query: ShareLinkDocument, variables: variables }
    }
export const TegusLinkDocument = gql`
    query tegusLink($csin: String!) {
  filesByFolderRecursive(csin: $csin, latestVersion: true) {
    edges {
      node {
        tegusLink
      }
    }
  }
}
    `;

/**
 * __useTegusLinkQuery__
 *
 * To run a query within a React component, call `useTegusLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useTegusLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTegusLinkQuery({
 *   variables: {
 *      csin: // value for 'csin'
 *   },
 * });
 */
export function useTegusLinkQuery(baseOptions: Apollo.QueryHookOptions<TegusLinkQuery, TegusLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TegusLinkQuery, TegusLinkQueryVariables>(TegusLinkDocument, options);
      }
export function useTegusLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TegusLinkQuery, TegusLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TegusLinkQuery, TegusLinkQueryVariables>(TegusLinkDocument, options);
        }
export type TegusLinkQueryHookResult = ReturnType<typeof useTegusLinkQuery>;
export type TegusLinkLazyQueryHookResult = ReturnType<typeof useTegusLinkLazyQuery>;
export type TegusLinkQueryResult = Apollo.QueryResult<TegusLinkQuery, TegusLinkQueryVariables>;
export function refetchTegusLinkQuery(variables?: TegusLinkQueryVariables) {
      return { query: TegusLinkDocument, variables: variables }
    }
export const WhatsNewPromosDocument = gql`
    query WhatsNewPromos($count: Int!) {
  promos(count: $count) {
    title
    description
    imageUrl
    imageAltText
    action {
      text
      link
      type
    }
  }
}
    `;

/**
 * __useWhatsNewPromosQuery__
 *
 * To run a query within a React component, call `useWhatsNewPromosQuery` and pass it any options that fit your needs.
 * When your component renders, `useWhatsNewPromosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWhatsNewPromosQuery({
 *   variables: {
 *      count: // value for 'count'
 *   },
 * });
 */
export function useWhatsNewPromosQuery(baseOptions: Apollo.QueryHookOptions<WhatsNewPromosQuery, WhatsNewPromosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WhatsNewPromosQuery, WhatsNewPromosQueryVariables>(WhatsNewPromosDocument, options);
      }
export function useWhatsNewPromosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WhatsNewPromosQuery, WhatsNewPromosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WhatsNewPromosQuery, WhatsNewPromosQueryVariables>(WhatsNewPromosDocument, options);
        }
export type WhatsNewPromosQueryHookResult = ReturnType<typeof useWhatsNewPromosQuery>;
export type WhatsNewPromosLazyQueryHookResult = ReturnType<typeof useWhatsNewPromosLazyQuery>;
export type WhatsNewPromosQueryResult = Apollo.QueryResult<WhatsNewPromosQuery, WhatsNewPromosQueryVariables>;
export function refetchWhatsNewPromosQuery(variables?: WhatsNewPromosQueryVariables) {
      return { query: WhatsNewPromosDocument, variables: variables }
    }
export const AddFolderModalCreateFolderDocument = gql`
    mutation AddFolderModalCreateFolder($folderName: String!, $parentFolderFiid: String!) {
  createFolder(folderName: $folderName, parentFolderFiid: $parentFolderFiid) {
    folderName
  }
}
    `;
export type AddFolderModalCreateFolderMutationFn = Apollo.MutationFunction<AddFolderModalCreateFolderMutation, AddFolderModalCreateFolderMutationVariables>;

/**
 * __useAddFolderModalCreateFolderMutation__
 *
 * To run a mutation, you first call `useAddFolderModalCreateFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFolderModalCreateFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFolderModalCreateFolderMutation, { data, loading, error }] = useAddFolderModalCreateFolderMutation({
 *   variables: {
 *      folderName: // value for 'folderName'
 *      parentFolderFiid: // value for 'parentFolderFiid'
 *   },
 * });
 */
export function useAddFolderModalCreateFolderMutation(baseOptions?: Apollo.MutationHookOptions<AddFolderModalCreateFolderMutation, AddFolderModalCreateFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddFolderModalCreateFolderMutation, AddFolderModalCreateFolderMutationVariables>(AddFolderModalCreateFolderDocument, options);
      }
export type AddFolderModalCreateFolderMutationHookResult = ReturnType<typeof useAddFolderModalCreateFolderMutation>;
export type AddFolderModalCreateFolderMutationResult = Apollo.MutationResult<AddFolderModalCreateFolderMutation>;
export type AddFolderModalCreateFolderMutationOptions = Apollo.BaseMutationOptions<AddFolderModalCreateFolderMutation, AddFolderModalCreateFolderMutationVariables>;
export const DeleteResourceModalDeleteResourceFileDocument = gql`
    mutation DeleteResourceModalDeleteResourceFile($id: ID!) {
  deleteResourceFile(id: $id) {
    id
  }
}
    `;
export type DeleteResourceModalDeleteResourceFileMutationFn = Apollo.MutationFunction<DeleteResourceModalDeleteResourceFileMutation, DeleteResourceModalDeleteResourceFileMutationVariables>;

/**
 * __useDeleteResourceModalDeleteResourceFileMutation__
 *
 * To run a mutation, you first call `useDeleteResourceModalDeleteResourceFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteResourceModalDeleteResourceFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteResourceModalDeleteResourceFileMutation, { data, loading, error }] = useDeleteResourceModalDeleteResourceFileMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteResourceModalDeleteResourceFileMutation(baseOptions?: Apollo.MutationHookOptions<DeleteResourceModalDeleteResourceFileMutation, DeleteResourceModalDeleteResourceFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteResourceModalDeleteResourceFileMutation, DeleteResourceModalDeleteResourceFileMutationVariables>(DeleteResourceModalDeleteResourceFileDocument, options);
      }
export type DeleteResourceModalDeleteResourceFileMutationHookResult = ReturnType<typeof useDeleteResourceModalDeleteResourceFileMutation>;
export type DeleteResourceModalDeleteResourceFileMutationResult = Apollo.MutationResult<DeleteResourceModalDeleteResourceFileMutation>;
export type DeleteResourceModalDeleteResourceFileMutationOptions = Apollo.BaseMutationOptions<DeleteResourceModalDeleteResourceFileMutation, DeleteResourceModalDeleteResourceFileMutationVariables>;
export const WatchListModalUserSearchDocument = gql`
    query WatchListModalUserSearch($query: String!, $first: Int, $id: ID) {
  results: users(query: $query, first: $first, excludeMe: true, canViewId: $id) {
    value: id
    display: name
    subtext: company
  }
}
    `;

/**
 * __useWatchListModalUserSearchQuery__
 *
 * To run a query within a React component, call `useWatchListModalUserSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useWatchListModalUserSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWatchListModalUserSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *      first: // value for 'first'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWatchListModalUserSearchQuery(baseOptions: Apollo.QueryHookOptions<WatchListModalUserSearchQuery, WatchListModalUserSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WatchListModalUserSearchQuery, WatchListModalUserSearchQueryVariables>(WatchListModalUserSearchDocument, options);
      }
export function useWatchListModalUserSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WatchListModalUserSearchQuery, WatchListModalUserSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WatchListModalUserSearchQuery, WatchListModalUserSearchQueryVariables>(WatchListModalUserSearchDocument, options);
        }
export type WatchListModalUserSearchQueryHookResult = ReturnType<typeof useWatchListModalUserSearchQuery>;
export type WatchListModalUserSearchLazyQueryHookResult = ReturnType<typeof useWatchListModalUserSearchLazyQuery>;
export type WatchListModalUserSearchQueryResult = Apollo.QueryResult<WatchListModalUserSearchQuery, WatchListModalUserSearchQueryVariables>;
export function refetchWatchListModalUserSearchQuery(variables?: WatchListModalUserSearchQueryVariables) {
      return { query: WatchListModalUserSearchDocument, variables: variables }
    }
export const WatchListModalUsersWatchingDocument = gql`
    query WatchListModalUsersWatching($id: ID!) {
  results: usersWatching(id: $id, excludeMe: true) {
    value: id
    display: name
    subtext: company
  }
}
    `;

/**
 * __useWatchListModalUsersWatchingQuery__
 *
 * To run a query within a React component, call `useWatchListModalUsersWatchingQuery` and pass it any options that fit your needs.
 * When your component renders, `useWatchListModalUsersWatchingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWatchListModalUsersWatchingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWatchListModalUsersWatchingQuery(baseOptions: Apollo.QueryHookOptions<WatchListModalUsersWatchingQuery, WatchListModalUsersWatchingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WatchListModalUsersWatchingQuery, WatchListModalUsersWatchingQueryVariables>(WatchListModalUsersWatchingDocument, options);
      }
export function useWatchListModalUsersWatchingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WatchListModalUsersWatchingQuery, WatchListModalUsersWatchingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WatchListModalUsersWatchingQuery, WatchListModalUsersWatchingQueryVariables>(WatchListModalUsersWatchingDocument, options);
        }
export type WatchListModalUsersWatchingQueryHookResult = ReturnType<typeof useWatchListModalUsersWatchingQuery>;
export type WatchListModalUsersWatchingLazyQueryHookResult = ReturnType<typeof useWatchListModalUsersWatchingLazyQuery>;
export type WatchListModalUsersWatchingQueryResult = Apollo.QueryResult<WatchListModalUsersWatchingQuery, WatchListModalUsersWatchingQueryVariables>;
export function refetchWatchListModalUsersWatchingQuery(variables?: WatchListModalUsersWatchingQueryVariables) {
      return { query: WatchListModalUsersWatchingDocument, variables: variables }
    }
export const WatchListModalUpdateWatchListDocument = gql`
    mutation WatchListModalUpdateWatchList($resourceId: ID!, $userIds: [ID!]) {
  updateWatchList(resourceId: $resourceId, userIds: $userIds) {
    results: watchList {
      value: id
      display: name
      subtext: company
    }
  }
}
    `;
export type WatchListModalUpdateWatchListMutationFn = Apollo.MutationFunction<WatchListModalUpdateWatchListMutation, WatchListModalUpdateWatchListMutationVariables>;

/**
 * __useWatchListModalUpdateWatchListMutation__
 *
 * To run a mutation, you first call `useWatchListModalUpdateWatchListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWatchListModalUpdateWatchListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [watchListModalUpdateWatchListMutation, { data, loading, error }] = useWatchListModalUpdateWatchListMutation({
 *   variables: {
 *      resourceId: // value for 'resourceId'
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useWatchListModalUpdateWatchListMutation(baseOptions?: Apollo.MutationHookOptions<WatchListModalUpdateWatchListMutation, WatchListModalUpdateWatchListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WatchListModalUpdateWatchListMutation, WatchListModalUpdateWatchListMutationVariables>(WatchListModalUpdateWatchListDocument, options);
      }
export type WatchListModalUpdateWatchListMutationHookResult = ReturnType<typeof useWatchListModalUpdateWatchListMutation>;
export type WatchListModalUpdateWatchListMutationResult = Apollo.MutationResult<WatchListModalUpdateWatchListMutation>;
export type WatchListModalUpdateWatchListMutationOptions = Apollo.BaseMutationOptions<WatchListModalUpdateWatchListMutation, WatchListModalUpdateWatchListMutationVariables>;
export const VersionsDocument = gql`
    query Versions($id: ID!, $first: Int!, $after: String) {
  folderItem(id: $id) {
    ... on Resource {
      allVersions(first: $first, after: $after) {
        pageInfo {
          ...VersionPageInfo
        }
        edges {
          node {
            ...VersionResource
          }
        }
      }
    }
  }
}
    ${VersionPageInfoFragmentDoc}
${VersionResourceFragmentDoc}`;

/**
 * __useVersionsQuery__
 *
 * To run a query within a React component, call `useVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVersionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useVersionsQuery(baseOptions: Apollo.QueryHookOptions<VersionsQuery, VersionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VersionsQuery, VersionsQueryVariables>(VersionsDocument, options);
      }
export function useVersionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VersionsQuery, VersionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VersionsQuery, VersionsQueryVariables>(VersionsDocument, options);
        }
export type VersionsQueryHookResult = ReturnType<typeof useVersionsQuery>;
export type VersionsLazyQueryHookResult = ReturnType<typeof useVersionsLazyQuery>;
export type VersionsQueryResult = Apollo.QueryResult<VersionsQuery, VersionsQueryVariables>;
export function refetchVersionsQuery(variables?: VersionsQueryVariables) {
      return { query: VersionsDocument, variables: variables }
    }
export const SearchDocument = gql`
    query Search($query: String!, $folder: ID, $first: Int!, $after: String, $includeFromFolder: Boolean!, $fromFolder: ID!, $includeSearchFolder: Boolean!, $searchFolder: ID!) {
  search(query: $query, folder: $folder, first: $first, after: $after) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      ...SearchSearchItemEdge
    }
  }
  searchFolder: folder(id: $searchFolder) @include(if: $includeSearchFolder) {
    ...SearchOrFromFolder
  }
  fromFolder: folder(id: $fromFolder) @include(if: $includeFromFolder) {
    ...SearchOrFromFolder
  }
  me {
    ...UserForDl
  }
}
    ${SearchSearchItemEdgeFragmentDoc}
${SearchOrFromFolderFragmentDoc}
${UserForDlFragmentDoc}`;

/**
 * __useSearchQuery__
 *
 * To run a query within a React component, call `useSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *      folder: // value for 'folder'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      includeFromFolder: // value for 'includeFromFolder'
 *      fromFolder: // value for 'fromFolder'
 *      includeSearchFolder: // value for 'includeSearchFolder'
 *      searchFolder: // value for 'searchFolder'
 *   },
 * });
 */
export function useSearchQuery(baseOptions: Apollo.QueryHookOptions<SearchQuery, SearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
      }
export function useSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchQuery, SearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
        }
export type SearchQueryHookResult = ReturnType<typeof useSearchQuery>;
export type SearchLazyQueryHookResult = ReturnType<typeof useSearchLazyQuery>;
export type SearchQueryResult = Apollo.QueryResult<SearchQuery, SearchQueryVariables>;
export function refetchSearchQuery(variables?: SearchQueryVariables) {
      return { query: SearchDocument, variables: variables }
    }
export const SideNavFolderByPathDocument = gql`
    query SideNavFolderByPath($path: String!) {
  folderByPath(path: $path) {
    id
    itemCountRecursive
  }
}
    `;

/**
 * __useSideNavFolderByPathQuery__
 *
 * To run a query within a React component, call `useSideNavFolderByPathQuery` and pass it any options that fit your needs.
 * When your component renders, `useSideNavFolderByPathQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSideNavFolderByPathQuery({
 *   variables: {
 *      path: // value for 'path'
 *   },
 * });
 */
export function useSideNavFolderByPathQuery(baseOptions: Apollo.QueryHookOptions<SideNavFolderByPathQuery, SideNavFolderByPathQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SideNavFolderByPathQuery, SideNavFolderByPathQueryVariables>(SideNavFolderByPathDocument, options);
      }
export function useSideNavFolderByPathLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SideNavFolderByPathQuery, SideNavFolderByPathQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SideNavFolderByPathQuery, SideNavFolderByPathQueryVariables>(SideNavFolderByPathDocument, options);
        }
export type SideNavFolderByPathQueryHookResult = ReturnType<typeof useSideNavFolderByPathQuery>;
export type SideNavFolderByPathLazyQueryHookResult = ReturnType<typeof useSideNavFolderByPathLazyQuery>;
export type SideNavFolderByPathQueryResult = Apollo.QueryResult<SideNavFolderByPathQuery, SideNavFolderByPathQueryVariables>;
export function refetchSideNavFolderByPathQuery(variables?: SideNavFolderByPathQueryVariables) {
      return { query: SideNavFolderByPathDocument, variables: variables }
    }
export const ResourceDisplayDocument = gql`
    query ResourceDisplay($count: Int!) {
  resourceDisplays(count: $count) {
    title
    description
    imageAltText
    imageUrl
    resourceType
    seriesId
    downloadUrl
    fiid
    slug
  }
}
    `;

/**
 * __useResourceDisplayQuery__
 *
 * To run a query within a React component, call `useResourceDisplayQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourceDisplayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourceDisplayQuery({
 *   variables: {
 *      count: // value for 'count'
 *   },
 * });
 */
export function useResourceDisplayQuery(baseOptions: Apollo.QueryHookOptions<ResourceDisplayQuery, ResourceDisplayQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResourceDisplayQuery, ResourceDisplayQueryVariables>(ResourceDisplayDocument, options);
      }
export function useResourceDisplayLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResourceDisplayQuery, ResourceDisplayQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResourceDisplayQuery, ResourceDisplayQueryVariables>(ResourceDisplayDocument, options);
        }
export type ResourceDisplayQueryHookResult = ReturnType<typeof useResourceDisplayQuery>;
export type ResourceDisplayLazyQueryHookResult = ReturnType<typeof useResourceDisplayLazyQuery>;
export type ResourceDisplayQueryResult = Apollo.QueryResult<ResourceDisplayQuery, ResourceDisplayQueryVariables>;
export function refetchResourceDisplayQuery(variables?: ResourceDisplayQueryVariables) {
      return { query: ResourceDisplayDocument, variables: variables }
    }
export const ResourcesResourcesDocument = gql`
    query ResourcesResources($toolkitAndGuidesId: ID!, $templatesId: ID!, $screensId: ID!, $toolkitAndGuidesOrder: FolderItemOrdering!, $defaultOrder: FolderItemOrdering!, $numItems: Int!) {
  toolkitAndGuides: folder(id: $toolkitAndGuidesId) {
    id
    items(ordering: $toolkitAndGuidesOrder, first: $numItems) {
      edges {
        node {
          ...ResourcesResource
        }
      }
    }
  }
  templates: folder(id: $templatesId) {
    id
    items(ordering: $defaultOrder, first: $numItems) {
      edges {
        node {
          ...ResourcesResource
        }
      }
    }
  }
  screens: folder(id: $screensId) {
    id
    items(ordering: $defaultOrder, first: $numItems) {
      edges {
        node {
          ...ResourcesResource
        }
      }
    }
  }
}
    ${ResourcesResourceFragmentDoc}`;

/**
 * __useResourcesResourcesQuery__
 *
 * To run a query within a React component, call `useResourcesResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourcesResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourcesResourcesQuery({
 *   variables: {
 *      toolkitAndGuidesId: // value for 'toolkitAndGuidesId'
 *      templatesId: // value for 'templatesId'
 *      screensId: // value for 'screensId'
 *      toolkitAndGuidesOrder: // value for 'toolkitAndGuidesOrder'
 *      defaultOrder: // value for 'defaultOrder'
 *      numItems: // value for 'numItems'
 *   },
 * });
 */
export function useResourcesResourcesQuery(baseOptions: Apollo.QueryHookOptions<ResourcesResourcesQuery, ResourcesResourcesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResourcesResourcesQuery, ResourcesResourcesQueryVariables>(ResourcesResourcesDocument, options);
      }
export function useResourcesResourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResourcesResourcesQuery, ResourcesResourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResourcesResourcesQuery, ResourcesResourcesQueryVariables>(ResourcesResourcesDocument, options);
        }
export type ResourcesResourcesQueryHookResult = ReturnType<typeof useResourcesResourcesQuery>;
export type ResourcesResourcesLazyQueryHookResult = ReturnType<typeof useResourcesResourcesLazyQuery>;
export type ResourcesResourcesQueryResult = Apollo.QueryResult<ResourcesResourcesQuery, ResourcesResourcesQueryVariables>;
export function refetchResourcesResourcesQuery(variables?: ResourcesResourcesQueryVariables) {
      return { query: ResourcesResourcesDocument, variables: variables }
    }
export const ResourcesFullResourcesDocument = gql`
    query ResourcesFullResources($folderId: ID!, $order: FolderItemOrdering!, $numItems: Int!) {
  folder(id: $folderId) {
    id
    items(ordering: $order, first: $numItems) {
      edges {
        node {
          ...ResourcesResource
        }
      }
    }
  }
}
    ${ResourcesResourceFragmentDoc}`;

/**
 * __useResourcesFullResourcesQuery__
 *
 * To run a query within a React component, call `useResourcesFullResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourcesFullResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourcesFullResourcesQuery({
 *   variables: {
 *      folderId: // value for 'folderId'
 *      order: // value for 'order'
 *      numItems: // value for 'numItems'
 *   },
 * });
 */
export function useResourcesFullResourcesQuery(baseOptions: Apollo.QueryHookOptions<ResourcesFullResourcesQuery, ResourcesFullResourcesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResourcesFullResourcesQuery, ResourcesFullResourcesQueryVariables>(ResourcesFullResourcesDocument, options);
      }
export function useResourcesFullResourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResourcesFullResourcesQuery, ResourcesFullResourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResourcesFullResourcesQuery, ResourcesFullResourcesQueryVariables>(ResourcesFullResourcesDocument, options);
        }
export type ResourcesFullResourcesQueryHookResult = ReturnType<typeof useResourcesFullResourcesQuery>;
export type ResourcesFullResourcesLazyQueryHookResult = ReturnType<typeof useResourcesFullResourcesLazyQuery>;
export type ResourcesFullResourcesQueryResult = Apollo.QueryResult<ResourcesFullResourcesQuery, ResourcesFullResourcesQueryVariables>;
export function refetchResourcesFullResourcesQuery(variables?: ResourcesFullResourcesQueryVariables) {
      return { query: ResourcesFullResourcesDocument, variables: variables }
    }
export const SearchDropdownDocument = gql`
    query SearchDropdown($query: String!, $first: Int!) {
  search(query: $query, first: $first) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      ...SearchDropdownSearchItemEdge
    }
  }
  me {
    ...UserForDl
  }
}
    ${SearchDropdownSearchItemEdgeFragmentDoc}
${UserForDlFragmentDoc}`;

/**
 * __useSearchDropdownQuery__
 *
 * To run a query within a React component, call `useSearchDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchDropdownQuery({
 *   variables: {
 *      query: // value for 'query'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useSearchDropdownQuery(baseOptions: Apollo.QueryHookOptions<SearchDropdownQuery, SearchDropdownQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchDropdownQuery, SearchDropdownQueryVariables>(SearchDropdownDocument, options);
      }
export function useSearchDropdownLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchDropdownQuery, SearchDropdownQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchDropdownQuery, SearchDropdownQueryVariables>(SearchDropdownDocument, options);
        }
export type SearchDropdownQueryHookResult = ReturnType<typeof useSearchDropdownQuery>;
export type SearchDropdownLazyQueryHookResult = ReturnType<typeof useSearchDropdownLazyQuery>;
export type SearchDropdownQueryResult = Apollo.QueryResult<SearchDropdownQuery, SearchDropdownQueryVariables>;
export function refetchSearchDropdownQuery(variables?: SearchDropdownQueryVariables) {
      return { query: SearchDropdownDocument, variables: variables }
    }
export const CreateShortUrlDocument = gql`
    mutation CreateShortUrl($longUrl: String!) {
  createShortUrl(longUrl: $longUrl) {
    shortUrl {
      ...MyShortUrlsShortUrl
    }
  }
}
    ${MyShortUrlsShortUrlFragmentDoc}`;
export type CreateShortUrlMutationFn = Apollo.MutationFunction<CreateShortUrlMutation, CreateShortUrlMutationVariables>;

/**
 * __useCreateShortUrlMutation__
 *
 * To run a mutation, you first call `useCreateShortUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShortUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShortUrlMutation, { data, loading, error }] = useCreateShortUrlMutation({
 *   variables: {
 *      longUrl: // value for 'longUrl'
 *   },
 * });
 */
export function useCreateShortUrlMutation(baseOptions?: Apollo.MutationHookOptions<CreateShortUrlMutation, CreateShortUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateShortUrlMutation, CreateShortUrlMutationVariables>(CreateShortUrlDocument, options);
      }
export type CreateShortUrlMutationHookResult = ReturnType<typeof useCreateShortUrlMutation>;
export type CreateShortUrlMutationResult = Apollo.MutationResult<CreateShortUrlMutation>;
export type CreateShortUrlMutationOptions = Apollo.BaseMutationOptions<CreateShortUrlMutation, CreateShortUrlMutationVariables>;
export const MyShortUrlsDocument = gql`
    query MyShortUrls($count: Int!) {
  myShortUrls(count: $count) {
    ...MyShortUrlsShortUrl
  }
}
    ${MyShortUrlsShortUrlFragmentDoc}`;

/**
 * __useMyShortUrlsQuery__
 *
 * To run a query within a React component, call `useMyShortUrlsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyShortUrlsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyShortUrlsQuery({
 *   variables: {
 *      count: // value for 'count'
 *   },
 * });
 */
export function useMyShortUrlsQuery(baseOptions: Apollo.QueryHookOptions<MyShortUrlsQuery, MyShortUrlsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyShortUrlsQuery, MyShortUrlsQueryVariables>(MyShortUrlsDocument, options);
      }
export function useMyShortUrlsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyShortUrlsQuery, MyShortUrlsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyShortUrlsQuery, MyShortUrlsQueryVariables>(MyShortUrlsDocument, options);
        }
export type MyShortUrlsQueryHookResult = ReturnType<typeof useMyShortUrlsQuery>;
export type MyShortUrlsLazyQueryHookResult = ReturnType<typeof useMyShortUrlsLazyQuery>;
export type MyShortUrlsQueryResult = Apollo.QueryResult<MyShortUrlsQuery, MyShortUrlsQueryVariables>;
export function refetchMyShortUrlsQuery(variables?: MyShortUrlsQueryVariables) {
      return { query: MyShortUrlsDocument, variables: variables }
    }
export const UserSettingsMeDocument = gql`
    query UserSettingsMe {
  me {
    id
    djangoGlobalPerms
    mfaSetupComplete
  }
}
    `;

/**
 * __useUserSettingsMeQuery__
 *
 * To run a query within a React component, call `useUserSettingsMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSettingsMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSettingsMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserSettingsMeQuery(baseOptions?: Apollo.QueryHookOptions<UserSettingsMeQuery, UserSettingsMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserSettingsMeQuery, UserSettingsMeQueryVariables>(UserSettingsMeDocument, options);
      }
export function useUserSettingsMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserSettingsMeQuery, UserSettingsMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserSettingsMeQuery, UserSettingsMeQueryVariables>(UserSettingsMeDocument, options);
        }
export type UserSettingsMeQueryHookResult = ReturnType<typeof useUserSettingsMeQuery>;
export type UserSettingsMeLazyQueryHookResult = ReturnType<typeof useUserSettingsMeLazyQuery>;
export type UserSettingsMeQueryResult = Apollo.QueryResult<UserSettingsMeQuery, UserSettingsMeQueryVariables>;
export function refetchUserSettingsMeQuery(variables?: UserSettingsMeQueryVariables) {
      return { query: UserSettingsMeDocument, variables: variables }
    }
export const ApiTokensDeleteDeleteTokenDocument = gql`
    mutation ApiTokensDeleteDeleteToken($id: ID!) {
  deleteApiToken(id: $id) {
    id
  }
}
    `;
export type ApiTokensDeleteDeleteTokenMutationFn = Apollo.MutationFunction<ApiTokensDeleteDeleteTokenMutation, ApiTokensDeleteDeleteTokenMutationVariables>;

/**
 * __useApiTokensDeleteDeleteTokenMutation__
 *
 * To run a mutation, you first call `useApiTokensDeleteDeleteTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApiTokensDeleteDeleteTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [apiTokensDeleteDeleteTokenMutation, { data, loading, error }] = useApiTokensDeleteDeleteTokenMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApiTokensDeleteDeleteTokenMutation(baseOptions?: Apollo.MutationHookOptions<ApiTokensDeleteDeleteTokenMutation, ApiTokensDeleteDeleteTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiTokensDeleteDeleteTokenMutation, ApiTokensDeleteDeleteTokenMutationVariables>(ApiTokensDeleteDeleteTokenDocument, options);
      }
export type ApiTokensDeleteDeleteTokenMutationHookResult = ReturnType<typeof useApiTokensDeleteDeleteTokenMutation>;
export type ApiTokensDeleteDeleteTokenMutationResult = Apollo.MutationResult<ApiTokensDeleteDeleteTokenMutation>;
export type ApiTokensDeleteDeleteTokenMutationOptions = Apollo.BaseMutationOptions<ApiTokensDeleteDeleteTokenMutation, ApiTokensDeleteDeleteTokenMutationVariables>;
export const EmailSettingsMeDocument = gql`
    query EmailSettingsMe {
  me {
    id
    emailSettings {
      emailRecentDownloadUpdate
      ipoSubscriptions {
        allFolders
        folders {
          id
          isChecked
          name
          sector
        }
      }
    }
  }
}
    `;

/**
 * __useEmailSettingsMeQuery__
 *
 * To run a query within a React component, call `useEmailSettingsMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailSettingsMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailSettingsMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useEmailSettingsMeQuery(baseOptions?: Apollo.QueryHookOptions<EmailSettingsMeQuery, EmailSettingsMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmailSettingsMeQuery, EmailSettingsMeQueryVariables>(EmailSettingsMeDocument, options);
      }
export function useEmailSettingsMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmailSettingsMeQuery, EmailSettingsMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmailSettingsMeQuery, EmailSettingsMeQueryVariables>(EmailSettingsMeDocument, options);
        }
export type EmailSettingsMeQueryHookResult = ReturnType<typeof useEmailSettingsMeQuery>;
export type EmailSettingsMeLazyQueryHookResult = ReturnType<typeof useEmailSettingsMeLazyQuery>;
export type EmailSettingsMeQueryResult = Apollo.QueryResult<EmailSettingsMeQuery, EmailSettingsMeQueryVariables>;
export function refetchEmailSettingsMeQuery(variables?: EmailSettingsMeQueryVariables) {
      return { query: EmailSettingsMeDocument, variables: variables }
    }
export const EmailSettingsUpdateEmailSettingsDocument = gql`
    mutation EmailSettingsUpdateEmailSettings($emailRecentDownloadUpdate: Boolean, $ipoSubscriptions: IpoSubscriptionInput) {
  updateEmailSettings(
    emailRecentDownloadUpdate: $emailRecentDownloadUpdate
    ipoSubscriptions: $ipoSubscriptions
  ) {
    user {
      id
      emailSettings {
        emailRecentDownloadUpdate
        ipoSubscriptions {
          allFolders
          folders {
            id
            isChecked
            name
            sector
          }
        }
      }
    }
  }
}
    `;
export type EmailSettingsUpdateEmailSettingsMutationFn = Apollo.MutationFunction<EmailSettingsUpdateEmailSettingsMutation, EmailSettingsUpdateEmailSettingsMutationVariables>;

/**
 * __useEmailSettingsUpdateEmailSettingsMutation__
 *
 * To run a mutation, you first call `useEmailSettingsUpdateEmailSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailSettingsUpdateEmailSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailSettingsUpdateEmailSettingsMutation, { data, loading, error }] = useEmailSettingsUpdateEmailSettingsMutation({
 *   variables: {
 *      emailRecentDownloadUpdate: // value for 'emailRecentDownloadUpdate'
 *      ipoSubscriptions: // value for 'ipoSubscriptions'
 *   },
 * });
 */
export function useEmailSettingsUpdateEmailSettingsMutation(baseOptions?: Apollo.MutationHookOptions<EmailSettingsUpdateEmailSettingsMutation, EmailSettingsUpdateEmailSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EmailSettingsUpdateEmailSettingsMutation, EmailSettingsUpdateEmailSettingsMutationVariables>(EmailSettingsUpdateEmailSettingsDocument, options);
      }
export type EmailSettingsUpdateEmailSettingsMutationHookResult = ReturnType<typeof useEmailSettingsUpdateEmailSettingsMutation>;
export type EmailSettingsUpdateEmailSettingsMutationResult = Apollo.MutationResult<EmailSettingsUpdateEmailSettingsMutation>;
export type EmailSettingsUpdateEmailSettingsMutationOptions = Apollo.BaseMutationOptions<EmailSettingsUpdateEmailSettingsMutation, EmailSettingsUpdateEmailSettingsMutationVariables>;
export const BackupTokensMyMfaBackupTokensDocument = gql`
    query BackupTokensMyMfaBackupTokens {
  myMfaBackupTokens
}
    `;

/**
 * __useBackupTokensMyMfaBackupTokensQuery__
 *
 * To run a query within a React component, call `useBackupTokensMyMfaBackupTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useBackupTokensMyMfaBackupTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBackupTokensMyMfaBackupTokensQuery({
 *   variables: {
 *   },
 * });
 */
export function useBackupTokensMyMfaBackupTokensQuery(baseOptions?: Apollo.QueryHookOptions<BackupTokensMyMfaBackupTokensQuery, BackupTokensMyMfaBackupTokensQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BackupTokensMyMfaBackupTokensQuery, BackupTokensMyMfaBackupTokensQueryVariables>(BackupTokensMyMfaBackupTokensDocument, options);
      }
export function useBackupTokensMyMfaBackupTokensLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BackupTokensMyMfaBackupTokensQuery, BackupTokensMyMfaBackupTokensQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BackupTokensMyMfaBackupTokensQuery, BackupTokensMyMfaBackupTokensQueryVariables>(BackupTokensMyMfaBackupTokensDocument, options);
        }
export type BackupTokensMyMfaBackupTokensQueryHookResult = ReturnType<typeof useBackupTokensMyMfaBackupTokensQuery>;
export type BackupTokensMyMfaBackupTokensLazyQueryHookResult = ReturnType<typeof useBackupTokensMyMfaBackupTokensLazyQuery>;
export type BackupTokensMyMfaBackupTokensQueryResult = Apollo.QueryResult<BackupTokensMyMfaBackupTokensQuery, BackupTokensMyMfaBackupTokensQueryVariables>;
export function refetchBackupTokensMyMfaBackupTokensQuery(variables?: BackupTokensMyMfaBackupTokensQueryVariables) {
      return { query: BackupTokensMyMfaBackupTokensDocument, variables: variables }
    }
export const BackupTokensUpdateMfaBackupTokensDocument = gql`
    mutation BackupTokensUpdateMfaBackupTokens {
  updateMfaBackupTokens {
    mfaBackupTokens
  }
}
    `;
export type BackupTokensUpdateMfaBackupTokensMutationFn = Apollo.MutationFunction<BackupTokensUpdateMfaBackupTokensMutation, BackupTokensUpdateMfaBackupTokensMutationVariables>;

/**
 * __useBackupTokensUpdateMfaBackupTokensMutation__
 *
 * To run a mutation, you first call `useBackupTokensUpdateMfaBackupTokensMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBackupTokensUpdateMfaBackupTokensMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [backupTokensUpdateMfaBackupTokensMutation, { data, loading, error }] = useBackupTokensUpdateMfaBackupTokensMutation({
 *   variables: {
 *   },
 * });
 */
export function useBackupTokensUpdateMfaBackupTokensMutation(baseOptions?: Apollo.MutationHookOptions<BackupTokensUpdateMfaBackupTokensMutation, BackupTokensUpdateMfaBackupTokensMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BackupTokensUpdateMfaBackupTokensMutation, BackupTokensUpdateMfaBackupTokensMutationVariables>(BackupTokensUpdateMfaBackupTokensDocument, options);
      }
export type BackupTokensUpdateMfaBackupTokensMutationHookResult = ReturnType<typeof useBackupTokensUpdateMfaBackupTokensMutation>;
export type BackupTokensUpdateMfaBackupTokensMutationResult = Apollo.MutationResult<BackupTokensUpdateMfaBackupTokensMutation>;
export type BackupTokensUpdateMfaBackupTokensMutationOptions = Apollo.BaseMutationOptions<BackupTokensUpdateMfaBackupTokensMutation, BackupTokensUpdateMfaBackupTokensMutationVariables>;
export const DisableMfaButtonDisableMfaDocument = gql`
    mutation DisableMfaButtonDisableMfa($userId: ID) {
  disableMfa(userId: $userId) {
    success
  }
}
    `;
export type DisableMfaButtonDisableMfaMutationFn = Apollo.MutationFunction<DisableMfaButtonDisableMfaMutation, DisableMfaButtonDisableMfaMutationVariables>;

/**
 * __useDisableMfaButtonDisableMfaMutation__
 *
 * To run a mutation, you first call `useDisableMfaButtonDisableMfaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableMfaButtonDisableMfaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableMfaButtonDisableMfaMutation, { data, loading, error }] = useDisableMfaButtonDisableMfaMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDisableMfaButtonDisableMfaMutation(baseOptions?: Apollo.MutationHookOptions<DisableMfaButtonDisableMfaMutation, DisableMfaButtonDisableMfaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisableMfaButtonDisableMfaMutation, DisableMfaButtonDisableMfaMutationVariables>(DisableMfaButtonDisableMfaDocument, options);
      }
export type DisableMfaButtonDisableMfaMutationHookResult = ReturnType<typeof useDisableMfaButtonDisableMfaMutation>;
export type DisableMfaButtonDisableMfaMutationResult = Apollo.MutationResult<DisableMfaButtonDisableMfaMutation>;
export type DisableMfaButtonDisableMfaMutationOptions = Apollo.BaseMutationOptions<DisableMfaButtonDisableMfaMutation, DisableMfaButtonDisableMfaMutationVariables>;
export const MfaInfoMeDocument = gql`
    query MfaInfoMe {
  me {
    mfaEnforced
  }
}
    `;

/**
 * __useMfaInfoMeQuery__
 *
 * To run a query within a React component, call `useMfaInfoMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMfaInfoMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMfaInfoMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMfaInfoMeQuery(baseOptions?: Apollo.QueryHookOptions<MfaInfoMeQuery, MfaInfoMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MfaInfoMeQuery, MfaInfoMeQueryVariables>(MfaInfoMeDocument, options);
      }
export function useMfaInfoMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MfaInfoMeQuery, MfaInfoMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MfaInfoMeQuery, MfaInfoMeQueryVariables>(MfaInfoMeDocument, options);
        }
export type MfaInfoMeQueryHookResult = ReturnType<typeof useMfaInfoMeQuery>;
export type MfaInfoMeLazyQueryHookResult = ReturnType<typeof useMfaInfoMeLazyQuery>;
export type MfaInfoMeQueryResult = Apollo.QueryResult<MfaInfoMeQuery, MfaInfoMeQueryVariables>;
export function refetchMfaInfoMeQuery(variables?: MfaInfoMeQueryVariables) {
      return { query: MfaInfoMeDocument, variables: variables }
    }
export const MfaSettingsMeDocument = gql`
    query MfaSettingsMe {
  me {
    id
    mfaEnforced
    mfaSetupComplete
  }
}
    `;

/**
 * __useMfaSettingsMeQuery__
 *
 * To run a query within a React component, call `useMfaSettingsMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMfaSettingsMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMfaSettingsMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMfaSettingsMeQuery(baseOptions?: Apollo.QueryHookOptions<MfaSettingsMeQuery, MfaSettingsMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MfaSettingsMeQuery, MfaSettingsMeQueryVariables>(MfaSettingsMeDocument, options);
      }
export function useMfaSettingsMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MfaSettingsMeQuery, MfaSettingsMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MfaSettingsMeQuery, MfaSettingsMeQueryVariables>(MfaSettingsMeDocument, options);
        }
export type MfaSettingsMeQueryHookResult = ReturnType<typeof useMfaSettingsMeQuery>;
export type MfaSettingsMeLazyQueryHookResult = ReturnType<typeof useMfaSettingsMeLazyQuery>;
export type MfaSettingsMeQueryResult = Apollo.QueryResult<MfaSettingsMeQuery, MfaSettingsMeQueryVariables>;
export function refetchMfaSettingsMeQuery(variables?: MfaSettingsMeQueryVariables) {
      return { query: MfaSettingsMeDocument, variables: variables }
    }
export const MfaSettingsAuth0RegenerateRecoveryTokenDocument = gql`
    mutation MfaSettingsAuth0RegenerateRecoveryToken($userId: ID!) {
  regenerateMfaRecoveryTokenAuth0(userId: $userId) {
    token
  }
}
    `;
export type MfaSettingsAuth0RegenerateRecoveryTokenMutationFn = Apollo.MutationFunction<MfaSettingsAuth0RegenerateRecoveryTokenMutation, MfaSettingsAuth0RegenerateRecoveryTokenMutationVariables>;

/**
 * __useMfaSettingsAuth0RegenerateRecoveryTokenMutation__
 *
 * To run a mutation, you first call `useMfaSettingsAuth0RegenerateRecoveryTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMfaSettingsAuth0RegenerateRecoveryTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mfaSettingsAuth0RegenerateRecoveryTokenMutation, { data, loading, error }] = useMfaSettingsAuth0RegenerateRecoveryTokenMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useMfaSettingsAuth0RegenerateRecoveryTokenMutation(baseOptions?: Apollo.MutationHookOptions<MfaSettingsAuth0RegenerateRecoveryTokenMutation, MfaSettingsAuth0RegenerateRecoveryTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MfaSettingsAuth0RegenerateRecoveryTokenMutation, MfaSettingsAuth0RegenerateRecoveryTokenMutationVariables>(MfaSettingsAuth0RegenerateRecoveryTokenDocument, options);
      }
export type MfaSettingsAuth0RegenerateRecoveryTokenMutationHookResult = ReturnType<typeof useMfaSettingsAuth0RegenerateRecoveryTokenMutation>;
export type MfaSettingsAuth0RegenerateRecoveryTokenMutationResult = Apollo.MutationResult<MfaSettingsAuth0RegenerateRecoveryTokenMutation>;
export type MfaSettingsAuth0RegenerateRecoveryTokenMutationOptions = Apollo.BaseMutationOptions<MfaSettingsAuth0RegenerateRecoveryTokenMutation, MfaSettingsAuth0RegenerateRecoveryTokenMutationVariables>;
export const MfaSettingsAuth0ResetMfaDocument = gql`
    mutation MfaSettingsAuth0ResetMfa($userId: ID!, $generateTicket: Boolean) {
  resetMfaAuth0(userId: $userId, generateTicket: $generateTicket) {
    success
    url
  }
}
    `;
export type MfaSettingsAuth0ResetMfaMutationFn = Apollo.MutationFunction<MfaSettingsAuth0ResetMfaMutation, MfaSettingsAuth0ResetMfaMutationVariables>;

/**
 * __useMfaSettingsAuth0ResetMfaMutation__
 *
 * To run a mutation, you first call `useMfaSettingsAuth0ResetMfaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMfaSettingsAuth0ResetMfaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mfaSettingsAuth0ResetMfaMutation, { data, loading, error }] = useMfaSettingsAuth0ResetMfaMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      generateTicket: // value for 'generateTicket'
 *   },
 * });
 */
export function useMfaSettingsAuth0ResetMfaMutation(baseOptions?: Apollo.MutationHookOptions<MfaSettingsAuth0ResetMfaMutation, MfaSettingsAuth0ResetMfaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MfaSettingsAuth0ResetMfaMutation, MfaSettingsAuth0ResetMfaMutationVariables>(MfaSettingsAuth0ResetMfaDocument, options);
      }
export type MfaSettingsAuth0ResetMfaMutationHookResult = ReturnType<typeof useMfaSettingsAuth0ResetMfaMutation>;
export type MfaSettingsAuth0ResetMfaMutationResult = Apollo.MutationResult<MfaSettingsAuth0ResetMfaMutation>;
export type MfaSettingsAuth0ResetMfaMutationOptions = Apollo.BaseMutationOptions<MfaSettingsAuth0ResetMfaMutation, MfaSettingsAuth0ResetMfaMutationVariables>;
export const MfaSettingsAuth0SetUpMfaDocument = gql`
    mutation MfaSettingsAuth0SetUpMfa($userId: ID!) {
  setUpMfaAuth0(userId: $userId) {
    url
  }
}
    `;
export type MfaSettingsAuth0SetUpMfaMutationFn = Apollo.MutationFunction<MfaSettingsAuth0SetUpMfaMutation, MfaSettingsAuth0SetUpMfaMutationVariables>;

/**
 * __useMfaSettingsAuth0SetUpMfaMutation__
 *
 * To run a mutation, you first call `useMfaSettingsAuth0SetUpMfaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMfaSettingsAuth0SetUpMfaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mfaSettingsAuth0SetUpMfaMutation, { data, loading, error }] = useMfaSettingsAuth0SetUpMfaMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useMfaSettingsAuth0SetUpMfaMutation(baseOptions?: Apollo.MutationHookOptions<MfaSettingsAuth0SetUpMfaMutation, MfaSettingsAuth0SetUpMfaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MfaSettingsAuth0SetUpMfaMutation, MfaSettingsAuth0SetUpMfaMutationVariables>(MfaSettingsAuth0SetUpMfaDocument, options);
      }
export type MfaSettingsAuth0SetUpMfaMutationHookResult = ReturnType<typeof useMfaSettingsAuth0SetUpMfaMutation>;
export type MfaSettingsAuth0SetUpMfaMutationResult = Apollo.MutationResult<MfaSettingsAuth0SetUpMfaMutation>;
export type MfaSettingsAuth0SetUpMfaMutationOptions = Apollo.BaseMutationOptions<MfaSettingsAuth0SetUpMfaMutation, MfaSettingsAuth0SetUpMfaMutationVariables>;
export const MfaSetupFormEnableMfaDocument = gql`
    mutation MfaSetupFormEnableMfa($token: String!) {
  enableMfa(token: $token) {
    user {
      id
    }
  }
}
    `;
export type MfaSetupFormEnableMfaMutationFn = Apollo.MutationFunction<MfaSetupFormEnableMfaMutation, MfaSetupFormEnableMfaMutationVariables>;

/**
 * __useMfaSetupFormEnableMfaMutation__
 *
 * To run a mutation, you first call `useMfaSetupFormEnableMfaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMfaSetupFormEnableMfaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mfaSetupFormEnableMfaMutation, { data, loading, error }] = useMfaSetupFormEnableMfaMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useMfaSetupFormEnableMfaMutation(baseOptions?: Apollo.MutationHookOptions<MfaSetupFormEnableMfaMutation, MfaSetupFormEnableMfaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MfaSetupFormEnableMfaMutation, MfaSetupFormEnableMfaMutationVariables>(MfaSetupFormEnableMfaDocument, options);
      }
export type MfaSetupFormEnableMfaMutationHookResult = ReturnType<typeof useMfaSetupFormEnableMfaMutation>;
export type MfaSetupFormEnableMfaMutationResult = Apollo.MutationResult<MfaSetupFormEnableMfaMutation>;
export type MfaSetupFormEnableMfaMutationOptions = Apollo.BaseMutationOptions<MfaSetupFormEnableMfaMutation, MfaSetupFormEnableMfaMutationVariables>;
export const MfaSetupPageMeDocument = gql`
    query MfaSetupPageMe {
  me {
    mfaSetupComplete
  }
}
    `;

/**
 * __useMfaSetupPageMeQuery__
 *
 * To run a query within a React component, call `useMfaSetupPageMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMfaSetupPageMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMfaSetupPageMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMfaSetupPageMeQuery(baseOptions?: Apollo.QueryHookOptions<MfaSetupPageMeQuery, MfaSetupPageMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MfaSetupPageMeQuery, MfaSetupPageMeQueryVariables>(MfaSetupPageMeDocument, options);
      }
export function useMfaSetupPageMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MfaSetupPageMeQuery, MfaSetupPageMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MfaSetupPageMeQuery, MfaSetupPageMeQueryVariables>(MfaSetupPageMeDocument, options);
        }
export type MfaSetupPageMeQueryHookResult = ReturnType<typeof useMfaSetupPageMeQuery>;
export type MfaSetupPageMeLazyQueryHookResult = ReturnType<typeof useMfaSetupPageMeLazyQuery>;
export type MfaSetupPageMeQueryResult = Apollo.QueryResult<MfaSetupPageMeQuery, MfaSetupPageMeQueryVariables>;
export function refetchMfaSetupPageMeQuery(variables?: MfaSetupPageMeQueryVariables) {
      return { query: MfaSetupPageMeDocument, variables: variables }
    }
export const MfaSetupStepsMyMfaSetupUrlDocument = gql`
    query MfaSetupStepsMyMfaSetupUrl {
  myMfaSetupUrl
}
    `;

/**
 * __useMfaSetupStepsMyMfaSetupUrlQuery__
 *
 * To run a query within a React component, call `useMfaSetupStepsMyMfaSetupUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useMfaSetupStepsMyMfaSetupUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMfaSetupStepsMyMfaSetupUrlQuery({
 *   variables: {
 *   },
 * });
 */
export function useMfaSetupStepsMyMfaSetupUrlQuery(baseOptions?: Apollo.QueryHookOptions<MfaSetupStepsMyMfaSetupUrlQuery, MfaSetupStepsMyMfaSetupUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MfaSetupStepsMyMfaSetupUrlQuery, MfaSetupStepsMyMfaSetupUrlQueryVariables>(MfaSetupStepsMyMfaSetupUrlDocument, options);
      }
export function useMfaSetupStepsMyMfaSetupUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MfaSetupStepsMyMfaSetupUrlQuery, MfaSetupStepsMyMfaSetupUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MfaSetupStepsMyMfaSetupUrlQuery, MfaSetupStepsMyMfaSetupUrlQueryVariables>(MfaSetupStepsMyMfaSetupUrlDocument, options);
        }
export type MfaSetupStepsMyMfaSetupUrlQueryHookResult = ReturnType<typeof useMfaSetupStepsMyMfaSetupUrlQuery>;
export type MfaSetupStepsMyMfaSetupUrlLazyQueryHookResult = ReturnType<typeof useMfaSetupStepsMyMfaSetupUrlLazyQuery>;
export type MfaSetupStepsMyMfaSetupUrlQueryResult = Apollo.QueryResult<MfaSetupStepsMyMfaSetupUrlQuery, MfaSetupStepsMyMfaSetupUrlQueryVariables>;
export function refetchMfaSetupStepsMyMfaSetupUrlQuery(variables?: MfaSetupStepsMyMfaSetupUrlQueryVariables) {
      return { query: MfaSetupStepsMyMfaSetupUrlDocument, variables: variables }
    }
export const UpdatePasswordMutationDocument = gql`
    mutation UpdatePasswordMutation($oldPassword: String!, $newPassword: String!, $newPasswordConfirmed: String!) {
  updatePassword(
    oldPassword: $oldPassword
    newPassword: $newPassword
    newPasswordConfirmed: $newPasswordConfirmed
  ) {
    success
  }
}
    `;
export type UpdatePasswordMutationMutationFn = Apollo.MutationFunction<UpdatePasswordMutationMutation, UpdatePasswordMutationMutationVariables>;

/**
 * __useUpdatePasswordMutationMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordMutationMutation, { data, loading, error }] = useUpdatePasswordMutationMutation({
 *   variables: {
 *      oldPassword: // value for 'oldPassword'
 *      newPassword: // value for 'newPassword'
 *      newPasswordConfirmed: // value for 'newPasswordConfirmed'
 *   },
 * });
 */
export function useUpdatePasswordMutationMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePasswordMutationMutation, UpdatePasswordMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePasswordMutationMutation, UpdatePasswordMutationMutationVariables>(UpdatePasswordMutationDocument, options);
      }
export type UpdatePasswordMutationMutationHookResult = ReturnType<typeof useUpdatePasswordMutationMutation>;
export type UpdatePasswordMutationMutationResult = Apollo.MutationResult<UpdatePasswordMutationMutation>;
export type UpdatePasswordMutationMutationOptions = Apollo.BaseMutationOptions<UpdatePasswordMutationMutation, UpdatePasswordMutationMutationVariables>;
export const UpdatePasswordCreateAuth0PasswordResetTicketDocument = gql`
    mutation UpdatePasswordCreateAuth0PasswordResetTicket($userId: ID) {
  createAuth0PasswordResetToken(userId: $userId) {
    ticket
  }
}
    `;
export type UpdatePasswordCreateAuth0PasswordResetTicketMutationFn = Apollo.MutationFunction<UpdatePasswordCreateAuth0PasswordResetTicketMutation, UpdatePasswordCreateAuth0PasswordResetTicketMutationVariables>;

/**
 * __useUpdatePasswordCreateAuth0PasswordResetTicketMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordCreateAuth0PasswordResetTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordCreateAuth0PasswordResetTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordCreateAuth0PasswordResetTicketMutation, { data, loading, error }] = useUpdatePasswordCreateAuth0PasswordResetTicketMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUpdatePasswordCreateAuth0PasswordResetTicketMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePasswordCreateAuth0PasswordResetTicketMutation, UpdatePasswordCreateAuth0PasswordResetTicketMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePasswordCreateAuth0PasswordResetTicketMutation, UpdatePasswordCreateAuth0PasswordResetTicketMutationVariables>(UpdatePasswordCreateAuth0PasswordResetTicketDocument, options);
      }
export type UpdatePasswordCreateAuth0PasswordResetTicketMutationHookResult = ReturnType<typeof useUpdatePasswordCreateAuth0PasswordResetTicketMutation>;
export type UpdatePasswordCreateAuth0PasswordResetTicketMutationResult = Apollo.MutationResult<UpdatePasswordCreateAuth0PasswordResetTicketMutation>;
export type UpdatePasswordCreateAuth0PasswordResetTicketMutationOptions = Apollo.BaseMutationOptions<UpdatePasswordCreateAuth0PasswordResetTicketMutation, UpdatePasswordCreateAuth0PasswordResetTicketMutationVariables>;
export const VariantPreferenceMeDocument = gql`
    query VariantPreferenceMe {
  me {
    id
    djangoGlobalPerms
    variantPreference {
      periodOrder
      driversWorksheets
      theme
    }
  }
}
    `;

/**
 * __useVariantPreferenceMeQuery__
 *
 * To run a query within a React component, call `useVariantPreferenceMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useVariantPreferenceMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVariantPreferenceMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useVariantPreferenceMeQuery(baseOptions?: Apollo.QueryHookOptions<VariantPreferenceMeQuery, VariantPreferenceMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VariantPreferenceMeQuery, VariantPreferenceMeQueryVariables>(VariantPreferenceMeDocument, options);
      }
export function useVariantPreferenceMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VariantPreferenceMeQuery, VariantPreferenceMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VariantPreferenceMeQuery, VariantPreferenceMeQueryVariables>(VariantPreferenceMeDocument, options);
        }
export type VariantPreferenceMeQueryHookResult = ReturnType<typeof useVariantPreferenceMeQuery>;
export type VariantPreferenceMeLazyQueryHookResult = ReturnType<typeof useVariantPreferenceMeLazyQuery>;
export type VariantPreferenceMeQueryResult = Apollo.QueryResult<VariantPreferenceMeQuery, VariantPreferenceMeQueryVariables>;
export function refetchVariantPreferenceMeQuery(variables?: VariantPreferenceMeQueryVariables) {
      return { query: VariantPreferenceMeDocument, variables: variables }
    }
export const VariantPreferenceUpdateUserVariantPreferenceDocument = gql`
    mutation VariantPreferenceUpdateUserVariantPreference($periodOrder: PeriodOrderKind!, $driversWorksheets: DriversWorksheetsKind!, $theme: ThemeKind!) {
  updateVariantPreference(
    periodOrder: $periodOrder
    driversWorksheets: $driversWorksheets
    theme: $theme
  ) {
    user {
      id
      variantPreference {
        periodOrder
        driversWorksheets
        theme
      }
    }
  }
}
    `;
export type VariantPreferenceUpdateUserVariantPreferenceMutationFn = Apollo.MutationFunction<VariantPreferenceUpdateUserVariantPreferenceMutation, VariantPreferenceUpdateUserVariantPreferenceMutationVariables>;

/**
 * __useVariantPreferenceUpdateUserVariantPreferenceMutation__
 *
 * To run a mutation, you first call `useVariantPreferenceUpdateUserVariantPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVariantPreferenceUpdateUserVariantPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [variantPreferenceUpdateUserVariantPreferenceMutation, { data, loading, error }] = useVariantPreferenceUpdateUserVariantPreferenceMutation({
 *   variables: {
 *      periodOrder: // value for 'periodOrder'
 *      driversWorksheets: // value for 'driversWorksheets'
 *      theme: // value for 'theme'
 *   },
 * });
 */
export function useVariantPreferenceUpdateUserVariantPreferenceMutation(baseOptions?: Apollo.MutationHookOptions<VariantPreferenceUpdateUserVariantPreferenceMutation, VariantPreferenceUpdateUserVariantPreferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VariantPreferenceUpdateUserVariantPreferenceMutation, VariantPreferenceUpdateUserVariantPreferenceMutationVariables>(VariantPreferenceUpdateUserVariantPreferenceDocument, options);
      }
export type VariantPreferenceUpdateUserVariantPreferenceMutationHookResult = ReturnType<typeof useVariantPreferenceUpdateUserVariantPreferenceMutation>;
export type VariantPreferenceUpdateUserVariantPreferenceMutationResult = Apollo.MutationResult<VariantPreferenceUpdateUserVariantPreferenceMutation>;
export type VariantPreferenceUpdateUserVariantPreferenceMutationOptions = Apollo.BaseMutationOptions<VariantPreferenceUpdateUserVariantPreferenceMutation, VariantPreferenceUpdateUserVariantPreferenceMutationVariables>;
export const UserSetupUserSetupDocument = gql`
    query UserSetupUserSetup {
  userSetup {
    id
    complete
    tasks {
      ...UserSetupTask
    }
  }
}
    ${UserSetupTaskFragmentDoc}`;

/**
 * __useUserSetupUserSetupQuery__
 *
 * To run a query within a React component, call `useUserSetupUserSetupQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSetupUserSetupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSetupUserSetupQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserSetupUserSetupQuery(baseOptions?: Apollo.QueryHookOptions<UserSetupUserSetupQuery, UserSetupUserSetupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserSetupUserSetupQuery, UserSetupUserSetupQueryVariables>(UserSetupUserSetupDocument, options);
      }
export function useUserSetupUserSetupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserSetupUserSetupQuery, UserSetupUserSetupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserSetupUserSetupQuery, UserSetupUserSetupQueryVariables>(UserSetupUserSetupDocument, options);
        }
export type UserSetupUserSetupQueryHookResult = ReturnType<typeof useUserSetupUserSetupQuery>;
export type UserSetupUserSetupLazyQueryHookResult = ReturnType<typeof useUserSetupUserSetupLazyQuery>;
export type UserSetupUserSetupQueryResult = Apollo.QueryResult<UserSetupUserSetupQuery, UserSetupUserSetupQueryVariables>;
export function refetchUserSetupUserSetupQuery(variables?: UserSetupUserSetupQueryVariables) {
      return { query: UserSetupUserSetupDocument, variables: variables }
    }
export const UserSetupAcceptUserAgreementDocument = gql`
    mutation UserSetupAcceptUserAgreement {
  acceptUserAgreement {
    success
  }
}
    `;
export type UserSetupAcceptUserAgreementMutationFn = Apollo.MutationFunction<UserSetupAcceptUserAgreementMutation, UserSetupAcceptUserAgreementMutationVariables>;

/**
 * __useUserSetupAcceptUserAgreementMutation__
 *
 * To run a mutation, you first call `useUserSetupAcceptUserAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserSetupAcceptUserAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userSetupAcceptUserAgreementMutation, { data, loading, error }] = useUserSetupAcceptUserAgreementMutation({
 *   variables: {
 *   },
 * });
 */
export function useUserSetupAcceptUserAgreementMutation(baseOptions?: Apollo.MutationHookOptions<UserSetupAcceptUserAgreementMutation, UserSetupAcceptUserAgreementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserSetupAcceptUserAgreementMutation, UserSetupAcceptUserAgreementMutationVariables>(UserSetupAcceptUserAgreementDocument, options);
      }
export type UserSetupAcceptUserAgreementMutationHookResult = ReturnType<typeof useUserSetupAcceptUserAgreementMutation>;
export type UserSetupAcceptUserAgreementMutationResult = Apollo.MutationResult<UserSetupAcceptUserAgreementMutation>;
export type UserSetupAcceptUserAgreementMutationOptions = Apollo.BaseMutationOptions<UserSetupAcceptUserAgreementMutation, UserSetupAcceptUserAgreementMutationVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "FolderItem": [
      "Folder",
      "Placeholder",
      "Resource"
    ],
    "Node": [
      "Resource"
    ]
  }
};
      export default result;
    